<template>
  <DataTable
    :value="accountTypes"
    class="c-datatable"
    responsive-layout="scroll"
    striped-rows
    sort-field="code"
    :sort-order="1"
    data-testid="c-account-settings-account-types-table"
  >
    <Column field="type" :header="t('account-settings.account-types.account-type')">
      <template #body="{ data, index }">
        <span :data-testid="`c-account-settings-account-types-type-${index}`">{{
          t(`account-settings.account-types.${data.type.toLowerCase()}`)
        }}</span>
      </template>
    </Column>
    <Column field="code" :header="t('account-settings.account-types.code')" class="w-24">
      <template #body="{ data, index }">
        <span :data-testid="`c-account-settings-account-types-code-${index}`">{{ data.code }}</span>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { AccountType } from "@/models/account-settings/AccountType";

defineProps<{
  accountTypes: AccountType[];
}>();

const { t } = useI18n();
</script>
