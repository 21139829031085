<template>
  <Tabs value="0" data-testid="add-credit-lines-tab">
    <TabList>
      <Tab value="0"> {{ t("creditnote.invoice-search") }} </Tab>
      <Tab value="1"> {{ t("creditnote.no-invoice-ref") }} </Tab>
    </TabList>
    <TabPanels>
      <TabPanel value="0">
        <InvoiceSearch
          :selected-customer-number="selectedCustomerNumber"
          :ordered-by-id="orderedById"
          :creditable-rows="creditableRows"
        />
      </TabPanel>
      <TabPanel value="1">
        <ProductSearch
          :creditable-rows="creditableRows"
          @add-without-reference-clicked="emit('addWithoutReferenceClicked')"
        />
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>

<script lang="ts" setup>
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import InvoiceSearch from "./InvoiceSearch.vue";
import { useI18n } from "vue-i18n";
import ProductSearch from "./ProductSearch.vue";

defineProps<{
  selectedCustomerNumber: string;
  orderedById: string;
  creditableRows: CreditableRow[];
}>();

const emit = defineEmits<{
  (e: "addWithoutReferenceClicked"): void;
}>();

const { t } = useI18n();
</script>
