<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        data-testid="btn-cancel"
        size="small"
        variant="text"
        @click="router.push({ name: 'invoice' })"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="px-2">{{ t("creditnote.new") }}</span>
      </Button>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <Button
        id="btn-save"
        class="c-circular-button"
        data-testid="btn-save"
        :disabled="showReturnStockDialog !== DialogState.Closed"
        @click="onClickSaveButton"
      >
        <i class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-4">{{ t("creditnote.save") }}</span>
      </Button>
    </div>
  </div>

  <div v-if="loading" class="c-overlay">
    <div class="c-spinner-container">
      <ProgressSpinner />
    </div>
  </div>

  <div class="c-credit-note">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 xl:col-span-6">
                  <div class="col-span-12">
                    <ContactPerson
                      v-model:contact-person="currentCreditNote.contactPerson"
                      :customer-contacts="customer.businessCustomer?.contacts ?? []"
                      :customer-type="customer.customerType"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactEmail v-model:email="currentCreditNote.contactPerson.email" />
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-6 ml-6">
                  <CustomerInfo :customer="currentCreditNote.orderedBy" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="formgrid grid grid-cols-12 gap-4">
                <div class="field col-span-12">
                  <CreditReference v-model:credit-reference="currentCreditNote.creditReference" />
                </div>
                <div class="field col-span-12">
                  <Comment v-model:comment="currentCreditNote.creditComment" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-12">
        <Card>
          <template #content>
            <div>
              <CreditableRows
                :creditable-rows="creditableRows"
                @initialize-values-to-credit="initializeValuesToCredit"
              />
              <CreditNoteFooter :total-sum="totalSumToCredit" />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
  <DecisionDialog
    v-model:visible="shouldShowAskToOpenReturnStockDialog"
    @return-inventory-clicked="openReturnStockDialog"
    @save-credit-note-clicked="saveCreditNote"
  />

  <ReturnToStockDialog
    v-model:visible="shouldShowReturnStockDialog"
    :new-credit-note="newCreditNote"
    :creditable-rows="creditableRowsViewModel"
    @save-credit-note="saveCreditNote"
  />
</template>

<script setup lang="ts">
import { useInvoiceStore } from "@/stores/InvoiceStore";
import { CreditableRow } from "../models/credit-note/CreditableRow";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { NewCreditNote } from "@/models/credit-note/NewCreditNote";
import { Invoice } from "@/models/invoice/Invoice";
import cloneDeep from "lodash.clonedeep";
import { useCustomerService } from "@/api/customer/CustomerService";
import { Customer } from "@/models/customer/Customer";
import { useCreditFromInvoicesStore } from "@/stores/CreditNoteFromInvoicesStore";
import { DialogState, useCreditNoteDialogService } from "@/utils/CreditNoteDialogService";
import { CreditNoteLine } from "@/models/credit-note/CreditNoteLine";
import useValidate from "@vuelidate/core";
import { type ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";
import { CreditableRowViewModel } from "@/models/credit-note/view-model/CreditableRowViewModel";

const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const val = useValidate();
const { t } = useI18n();

const customer = ref<Customer>(new Customer());
const invoices = ref<Invoice[]>([]);
const creditableRows = ref<CreditableRow[]>([]);
const newCreditNote = ref<NewCreditNote>(new NewCreditNote());
const loading = ref(false);

const creditStore = useCreditFromInvoicesStore();
const invoiceStore = useInvoiceStore();
const { currentCreditNote, initialCreditNote, isCreditNoteChanged } = storeToRefs(creditStore);
const creditableRowsViewModel = computed(() => creditableRows.value as CreditableRowViewModel[]);

const { getCustomerById } = useCustomerService();
const {
  showReturnStockDialog,
  shouldShowAskToOpenReturnStockDialog,
  shouldShowReturnStockDialog,
  askToOpenReturnStockDialog,
  openReturnStockDialog,
} = useCreditNoteDialogService();

const invoiceIds = computed<string[]>(() => {
  const ids: string | string[] | undefined = route.query.invoiceIds as unknown as undefined;
  return [].concat(ids ?? []);
});

const saveCreditNote = async (returnProducts?: ReturnProductInfo[]) => {
  await creditStore.createCreditNote(returnProducts);
  router.push({ name: "invoice" });
};

const initializeValuesToCredit = (row: CreditableRow) => {
  const previouslyCredited = invoiceStore.creditTotals
    .flatMap((t) => t.creditedInvoiceLines.map((l) => ({ ...l, invoiceId: t.id })))
    .find((t) => t.invoiceId === row.invoiceId && t.positionNumber === row.positionNumber);

  row.quantityToCredit = row.quantity ? row.quantity - (previouslyCredited?.quantity ?? 0) : null;
  row.sumToCredit = row.invoicedSum - (previouslyCredited?.sumLine ?? 0);
  row.listPriceToCredit = row.sumToCredit / (row.quantityToCredit || 1);

  if (previouslyCredited) {
    row.previouslyCreditedQuantity = previouslyCredited.quantity;
    row.previouslyCreditedSum = previouslyCredited.sumLine;

    if ((row.quantityToCredit ?? 0) * (row.listPriceToCredit ?? 0) > row.sumToCredit + 0.01) {
      row.quantityToCredit = 0;
      row.listPriceToCredit = 0;
    }
  }

  if ((row.quantityToCredit ?? 0) === 0 && row.sumToCredit === 0) {
    row.included = false;
  }
};

const totalSumToCredit = computed<number>(() =>
  creditableRows.value.filter((credit) => credit.included).reduce((sum, row) => sum + row.sumToCredit, 0),
);

const initializeCreditableRows = () => {
  const uncreditedInvoices = invoiceStore.invoices.filter((invoice) => {
    return !creditableRows.value.some((row) => row.invoiceId === invoice.id);
  });

  for (const i of uncreditedInvoices.filter((i) => invoiceIds.value.includes(i.id))) {
    for (const row of i.invoiceLines) {
      const newCreditableRow = CreditableRow.createFromInvoiceAndLine(i, row);

      initializeValuesToCredit(newCreditableRow);
      creditableRows.value.push(newCreditableRow);
    }

    const freightRow = CreditableRow.createFreightRow(i);

    initializeValuesToCredit(freightRow);
    creditableRows.value.push(freightRow);
  }
};

onMounted(async () => {
  try {
    loading.value = true;

    creditStore.clearStore();
    await Promise.all([invoiceStore.getInvoices(), invoiceStore.getCreditTotals(invoiceIds.value)]);
    invoices.value = invoiceStore.invoices.filter((invoice) => invoiceIds.value.includes(invoice.id));

    customer.value = await getCustomerById(invoices.value[0].orderedBy.id);
    creditStore.setCustomerInfo(customer.value);
    initializeCreditableRows();

    currentCreditNote.value.currencyIso = invoices.value[0].currencyIso;
    currentCreditNote.value.kid = invoices.value[0].kid;
    currentCreditNote.value.orderedBy = invoices.value[0].orderedBy;
    currentCreditNote.value.client = {
      clientId: invoices.value[0].client.clientId,
      name: invoices.value[0].client.name,
      businessNumber: invoices.value[0].client.businessNumber,
      phoneNumber: invoices.value[0].client.phoneNumber,
      email: invoices.value[0].client.email,
      address: invoices.value[0].client.address,
      accountNumber: invoices.value[0].accountNumber,
      bankName: invoices.value[0].bankName,
      logoBase64: invoices.value[0].client.logoBase64,
    };
    currentCreditNote.value.shippingPrice = invoices.value[0].shippingPrice;
    currentCreditNote.value.sumTotalLines = invoices.value[0].sumTotalLines;
    initialCreditNote.value = cloneDeep(currentCreditNote.value);
  } finally {
    loading.value = false;
  }
});

const onClickSaveButton = async () => {
  if (invoices.value.length === 0) {
    toast.add({
      severity: "error",
      summary: t("creditnote.add.toast.no-invoices"),
    });
    return;
  }

  if (new Set(invoices.value.map((i) => i.currencyIso)).size > 1) {
    toast.add({
      severity: "error",
      summary: t("creditnote.add.toast.too-many-currencies"),
    });
    return;
  }

  if (new Set(invoices.value.map((i) => i.client.clientId)).size > 1) {
    toast.add({
      severity: "error",
      summary: t("creditnote.add.toast.too-many-clients"),
    });
    return;
  }

  const add = (x: number, y: number) => {
    return x + y;
  };

  const creditNoteLines = CreditNoteLine.mapFromCreditRows(creditableRows.value);

  currentCreditNote.value.creditNoteLines = creditNoteLines;
  currentCreditNote.value.sumTotalLines = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalSum = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalVatAmount = creditNoteLines.map((x) => x.sumLineVatAmount).reduce(add, 0);
  currentCreditNote.value.totalSumIncVat = creditNoteLines.map((x) => x.sumLineIncVat).reduce(add, 0);

  if (currentCreditNote.value.creditNoteLines.length === 0) {
    toast.add({
      severity: "warn",
      summary: t("creditnote.add.toast.warn"),
    });
    return;
  }

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  askToOpenReturnStockDialog();
};

useUnsavedChanges(isCreditNoteChanged);
</script>

<style scoped lang="scss">
.c-bottom-line {
  display: flex;
  justify-content: end;
}

.c-credit-note {
  margin: var(--default-content-margin);
}

:deep(.p-inputtext) {
  &:enabled:focus {
    border: var(--floating-input-border-on-focus);
    box-shadow: none;
  }
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
