<template>
  <div class="c-number-series-list">
    <DataTable
      :value="numberSeries"
      dataKey="id"
      :autoLayout="true"
      @row-dblclick="onRowDblClick"
      responsiveLayout="scroll"
      selectionMode="single"
      :loading="loading"
      stripedRows
      :paginator="false"
      :rows="3"
      class="c-datatable"
      data-testid="c-number-series-list"
      resizableColumns
      columnResizeMode="expand"
      showGridlines
    >
      <Column :header="t(`number-series.column.type-finance`)" headerClass="w-12">
        <template #body="{ data }">
          <span :data-testid="`type-${data.seriesType.toLowerCase()}`">{{
            !data?.seriesType ? "" : t(`number-series.${data.seriesType.toLowerCase()}`)
          }}</span>
        </template>
      </Column>

      <Column :header="t(`number-series.column.start-number`)" headerClass="w-8" bodyClass="text-right">
        <template #body="{ data }">
          <span :data-testid="`start-number-${data?.seriesType?.toLowerCase()}`">{{ data.startNumber }}</span>
        </template>
      </Column>

      <Column :header="t(`number-series.column.last-number`)" headerClass="w-12" bodyClass="text-right">
        <template #body="{ data }">
          <span :data-testid="`last-used-${data?.seriesType?.toLowerCase()}`">{{ data.lastUsedNumber }}</span>
        </template>
      </Column>

      <Column headerClass="w-12" bodyClass="text-right max-w-16">
        <template #body="{ data }">
          <PrimeButton
            type="button"
            :data-testid="`edit-${data.seriesType.toLowerCase()}`"
            @click="editNumberSeries(data)"
            text
          >
            <i class="pi pi-pencil"></i>
          </PrimeButton>
        </template>
      </Column>

      <template #empty>{{ t("number-series.empty-list") }} </template>
      <template #loading>
        <div>
          <div class="flex justify-center">
            <ProgressSpinner v-if="loading" aria-label="Loading" class="c-number-spinner" />
          </div>
          <div>{{ t("number-series.loading-list") }}</div>
        </div>
      </template>
    </DataTable>

    <DialogEditFinanceNumberSeries
      v-if="showEditDialog && dialogNumberSeries"
      v-model:showDialog="showEditDialog"
      :numberSeries="dialogNumberSeries"
    >
    </DialogEditFinanceNumberSeries>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import DialogEditFinanceNumberSeries from "./DialogEditFinanceNumberSeries.vue";
import { FinanceNumberSeries } from "../model/finance/FinanceNumberSeries";

const { t } = useI18n();

const props = defineProps<{
  numberSeries: FinanceNumberSeries[];
  loading: boolean;
}>();

const numberSeries = computed<FinanceNumberSeries[]>(() => props.numberSeries);

const dialogNumberSeries = ref<FinanceNumberSeries>();

const showEditDialog = ref(false);

const editNumberSeries = (value: FinanceNumberSeries) => {
  dialogNumberSeries.value = value;
  showEditDialog.value = true;
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editNumberSeries(event.data);
};
</script>
