<template>
  <PrimeButton
    class="c-circular-button c-box-shadow mb-6"
    data-testid="btn-add-currency"
    :disabled="isSaving"
    @click="showAddCurrencyDialog = true"
  >
    <ProgressSpinner v-if="isSaving" class="c-spinner" />
    <i class="pi pi-plus c-success-button c-circular-icon"></i>
    <span class="px-4">Add currency</span>
  </PrimeButton>
  <DataTable
    v-model:selection="selectedCurrency"
    :value="currencies"
    data-testid="c-currency-list"
    class="c-datatable"
    selectionMode="single"
    dataKey="id"
    :loading="loading"
    striped-rows
    @row-dblclick="onCurrencyDblClick"
  >
    <Column field="currencyIso" :header="t('currency.iso')" class="text-center c-margin-auto" />
    <Column field="description" :header="t('currency.description')" class="text-center c-margin-auto" />
    <Column field="countryIso" :header="t('currency.country')" class="text-center c-margin-auto">
      <template #body="{ data }">
        {{ getCountryNameFromIso(data.countryIso) }}
      </template>
    </Column>
    <Column field="unit" :header="t('currency.unit')" class="text-center c-margin-auto" />
    <Column field="buyRate" :header="t('currency.buy-rate')" class="w-1/12 c-margin-auto">
      <template #body="{ data }">
        <ExchangeRateInput
          v-model:exchangeRate="data.buyRate"
          @exchangeRateUpdated="(event) => upsertCurrencyBuyRate(event, data)"
        />
      </template>
    </Column>
    <Column field="sellRate" :header="t('currency.sell-rate')" class="text-center c-margin-auto">
      <template #body="{ data }">
        {{ n(data.sellRate, "decimal") }}
      </template>
    </Column>
    <Column field="baseCurrencyIso" :header="t('currency.base-currency')" class="text-center c-margin-auto" />
    <Column field="lastModified" :header="t('currency.last-updated')" class="text-center c-margin-auto">
      <template #body="{ data }">
        {{ d(data.lastModified, "long") }}
      </template>
    </Column>
    <Column field="state" :header="t('currency.active')" class="text-center c-margin-auto">
      <template #body="{ data }">
        <Checkbox :modelValue="isCurrencyActive(data.currencyState)" :binary="true" disabled />
      </template>
    </Column>
  </DataTable>
  <div class="flex justify-end mt-6">
    <PrimeButton
      class="c-circular-button c-box-shadow"
      data-testid="btn-save-currency-exchange-rates"
      :disabled="isSaving"
      @click="onUpdateCurrencyExchangeRates"
    >
      <ProgressSpinner v-if="isSaving" class="c-spinner" />
      <i class="pi pi-check c-success-button c-circular-icon"></i>
      <span class="px-4">{{ t("common.save-changes") }}</span>
    </PrimeButton>
  </div>

  <AddCurrencyDialog
    v-if="showAddCurrencyDialog"
    v-model:showAddCurrencyDialog="showAddCurrencyDialog"
    :countries="countries"
    @currencyAdded="showAddCurrencyDialog = false"
  />
  <UpdateCurrencyDialog
    v-if="showUpdateCurrencyDialog"
    v-model:showUpdateCurrencyDialog="showUpdateCurrencyDialog"
    :currency="selectedCurrency"
    :countries="countries"
    @currencyUpdated="showUpdateCurrencyDialog = false"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { CurrencyInfo } from "@/models/currency/CurrencyInfo";
import { useCurrencyService } from "@/api/currency/CurrencyService";
import { useI18n } from "vue-i18n";
import { CurrencyState } from "@/models/currency/CurrencyState";
import { useCountry } from "@/api/country/CountryService";
import { Country } from "@/models/country/Country";
import { CurrencyExchangeRate } from "@/models/currency/CurrencyExchangeRate";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import useValidate from "@vuelidate/core";
import ExchangeRateInput from "./ExchangeRateInput.vue";
import AddCurrencyDialog from "./AddCurrencyDialog.vue";
import UpdateCurrencyDialog from "./UpdateCurrencyDialog.vue";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import { Currency } from "@/models/currency/Currency";

const currencies = ref<CurrencyInfo[]>([]);
const currencyExchangeRates = ref<CurrencyExchangeRate[]>([]);
const countries = ref<Country[]>([]);
const loading = ref(false);
const isSaving = ref(false);
const { t, d, n } = useI18n();
const toast = useCumulusToast(useToast());
const val = useValidate();
const showAddCurrencyDialog = ref(false);
const showUpdateCurrencyDialog = ref(false);
const selectedCurrency = ref<Currency>(new Currency());

const { getAllCurrencies, updateCurrencyExchangeRates } = useCurrencyService();
const { getCountries } = useCountry();

const isCurrencyActive = (state: CurrencyState) => {
  return state === CurrencyState.Active ? true : false;
};

const getCountryNameFromIso = (countryIso: string) => {
  const country = countries.value.find((country) => country.iso === countryIso);
  return country ? country.name : "";
};

const upsertCurrencyBuyRate = (buyRate: number, currency: CurrencyInfo) => {
  const currencyExchangeRate = currencyExchangeRates.value.find(
    (currencyExchangeRate) => currencyExchangeRate.id === currency.id,
  );
  if (currencyExchangeRate) {
    currencyExchangeRate.buyRate = buyRate;
  } else {
    currencyExchangeRates.value.push({
      id: currency.id,
      companyId: currency.companyId,
      buyRate: buyRate,
      sellRate: currency.sellRate,
    });
  }
};

const onCurrencyDblClick = (event: DataTableRowDoubleClickEvent) => {
  selectedCurrency.value = event.data;
  showUpdateCurrencyDialog.value = true;
};

const onUpdateCurrencyExchangeRates = async () => {
  if (currencyExchangeRates.value.length === 0) {
    return;
  }

  val.value.$touch();
  if (!(await val.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  try {
    isSaving.value = true;
    await updateCurrencyExchangeRates(currencyExchangeRates.value);

    toast.add({
      severity: "success",
      summary: t("currency.save-rates-summary"),
      detail: t("currency.save-rates-detail", { count: currencyExchangeRates.value.length }),
    });
  } finally {
    isSaving.value = false;
  }
};

onMounted(async () => {
  try {
    loading.value = true;
    currencies.value = await getAllCurrencies();
    countries.value = await getCountries();
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped lang="scss">
.c-datatable :deep(.p-datatable-thead > tr > th.c-margin-auto > div > span) {
  margin: auto;
}

.c-box-shadow {
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
</style>
