import {
  initMsalInstance,
  msalPlugin,
  msalInstance,
  AuthenticationResult,
  EventType,
  LogLevel,
} from "@cumulus/event-bus";

const logLevel = (level: string): LogLevel => {
  if (!level) {
    return LogLevel.Info;
  }

  switch (level.toLocaleLowerCase()) {
    case "error":
      return LogLevel.Error;
    case "warning":
      return LogLevel.Warning;
    case "info":
      return LogLevel.Info;
    case "verbose":
      return LogLevel.Verbose;
  }

  return LogLevel.Info;
};

if (import.meta.env.VITE_APP_STANDALONE === "true") {
  initMsalInstance(
    typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
      ? import.meta.env.VITE_APP_API_COMPANY
      : `${import.meta.env.VITE_APP_API_URL as string}`,
    import.meta.env.VITE_APP_B2C_DOMAIN as string,
    import.meta.env.VITE_APP_B2C_CLIENTID as string,
    import.meta.env.VITE_APP_B2C_INSTANCE as string,
    logLevel(import.meta.env.VITE_APP_B2C_LOG_LEVEL as string),
  );
}

// Account selection logic is app dependent. Adjust as needed for different use cases.
msalInstance().then((msal) => {
  const accounts = msal.getAllAccounts();
  if (accounts.length > 0) {
    msal.setActiveAccount(accounts[0]);
  }
});

msalInstance().then((msal) => {
  if (msal == undefined) {
    return;
  }

  msal.addEventCallback((event) => {
    if (event == undefined) {
      return;
    }

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msal.setActiveAccount(account);
    }
  });
});

export { msalInstance, msalPlugin };
