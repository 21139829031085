import { useAuth } from "@cumulus/event-bus";
import { AccountSettings } from "../../models/account-settings/AccountSettings";
import { accountSettingsApi } from "./AccountSettingsApi";
import { useErrorHandler } from "../ErrorHandler";
import { AccountSettingsInfo } from "@/models/account-settings/AccountSettingsInfo";

export function useAccountSettingsService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAccountSettings = async (id: string): Promise<AccountSettings> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await accountSettingsApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllAccountSettings = async (): Promise<AccountSettingsInfo[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await accountSettingsApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createDefaultAccountSettings = async (clientIds: string[]): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();

      return (await accountSettingsApi.createDefault(authHeaders, clientIds)).accountSettingsId;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateAccountSettings = async (accountSettings: AccountSettings): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await accountSettingsApi.update(authHeaders, accountSettings);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteAccountSettings = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await accountSettingsApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAccountSettings,
    getAllAccountSettings,
    createDefaultAccountSettings,
    updateAccountSettings,
    deleteAccountSettings,
  };
}
