<template>
  <ContextMenu ref="cm" :model="menuModel" data-testid="invoice-context-menu" />
  <div class="card">
    <ColumnOptionsMenu :label="t('invoice.header')" :itemsList="items"></ColumnOptionsMenu>
    <DataTable
      ref="invoiceSearchResult"
      :key="renderKey as unknown as number"
      v-model:selection="selectedInvoices"
      v-model:filters="filters"
      :value="filteredInvoices"
      dataKey="id"
      :autoLayout="true"
      responsiveLayout="stack"
      breakpoint="999px"
      selectionMode="multiple"
      data-testId="invoice-table"
      class="c-invoice-table c-datatable"
      :loading="loading"
      :sortField="sortField"
      :sortOrder="sortOrder"
      :paginator="true"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      stripedRows
      scrollable
      scrollHeight="75vh"
      :metaKeySelection="false"
      filterDisplay="menu"
      removableSort
      :resizableColumns="true"
      columnResizeMode="fit"
      :reorderable-columns="true"
      @row-dblclick="onRowDblClick"
      @row-select="onRowSelected"
      @rowContextmenu="onRowContextMenu"
      @sort="onSort"
      @page="onPage"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template v-if="col.field === InvoiceLinesColumns.OrderedByCustomerNumber" #filter="{ filterModel, field }">
          <InputText
            v-model="filterModel.value"
            type="text"
            class="p-column-filter"
            :placeholder="t('invoice.filter-by', { field: field })"
            data-testid="customer-number-filter"
            @input="onCustomerNumberInput(filterModel.value)"
        /></template>
        <template #body="{ field, data, index }">
          <template v-if="col.field === InvoiceLinesColumns.InvoiceDate">
            {{ d(data[field], "long") }}
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.InvoiceNumber">
            {{ data.invoiceNumber }}
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.OrderNumber">
            <span>{{ data.orderNumber }}</span>
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.FreightMethodName">
            <span>{{ data.freightMethodName }}</span>
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.OrderedByCustomerNumber">
            <span>{{ data.orderedBy.customerNumber }}</span>
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.OrderedByCustomerName">
            <span>{{ data.orderedBy.customerName }}</span>
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.SumTotalLines">
            {{ n(data[field], "decimal") }}
          </template>
          <template v-else-if="col.field === InvoiceLinesColumns.Pdf">
            <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="downloadPdf(data.id)" />
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("invoice.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
  <InvoicePrintDialog v-if="showPrintModal" v-model:visibleDialog="showPrintModal" :invoices="selectedInvoices" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import { useInvoiceStore } from "@/stores/InvoiceStore";
import { Invoice } from "@/models/invoice/Invoice";
import { useDocument } from "@/api/document/DocumentService";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import InvoicePrintDialog from "../components/InvoicePrintDialog.vue";
import { FilterMatchMode } from "@primevue/core/api";
import { useAuth } from "@cumulus/event-bus";
import { InvoiceLinesColumns } from "@/models/invoice/InvoiceLinesColumns";

const { getUser } = useAuth();

const { t, n, d } = useI18n();
const toast = useCumulusToast(useToast());
const invoiceStore = useInvoiceStore();
const { getInvoices, getFilteredInvoices } = invoiceStore;
const { invoices, loading } = storeToRefs(invoiceStore);
const { getInvoiceDocumentUrl } = useDocument();
const query = ref("");
const loadFailed = ref(false);
const pageSize = ref<number>(50);
const invoiceSearchResult = ref();
const cm = ref();
const sortField = ref("");
const sortOrder = ref(-1);
const visible = ref(false);

const selectedInvoices = defineModel<Invoice[]>("selectedInvoices", {
  required: true,
});

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "navigateToCreditNote"): void;
}>();

const invoiceSearchColumns: DataTableColumn[] = [
  { field: "invoiceDate", header: t("invoice.invoice-date"), sortable: true },
  {
    field: "invoiceNumber",
    header: t("invoice.invoice-number"),
    class: "text-right",
    sortable: true,
  },
  { field: "orderNumber", header: t("invoice.order-number"), class: "text-right", sortable: true },
  { field: "freightMethodName", header: t("invoice.freight-method-name"), class: "text-right", sortable: true },
  { field: "orderedBy.customerNumber", header: t("invoice.customer-number"), class: "text-right", sortable: true },
  { field: "orderedBy.customerName", header: t("invoice.customer-name"), class: "text-right", sortable: true },
  {
    field: "sumTotalLines",
    header: t("invoice.sum-of-total-ex-vat"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "pdf",
    header: "PDF",
    class: "c-pdf-icon",
    sortable: false,
  },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("invoiceSearch", invoiceSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== InvoiceLinesColumns.InvoiceNumber &&
      col.field !== InvoiceLinesColumns.OrderNumber &&
      col.field !== InvoiceLinesColumns.OrderedByCustomerName,
  );
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickCreditNoteRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? invoiceSearchColumns
    : invoiceSearchColumns.filter(
        (c) =>
          c.field === InvoiceLinesColumns.InvoiceNumber ||
          c.field === InvoiceLinesColumns.OrderNumber ||
          c.field === InvoiceLinesColumns.OrderedByCustomerName,
      );
};

const onClickCreditNoteRefresh = async () => {
  loading.value = true;
  await getInvoices();
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const showPrintModal = ref(false);
const menuModel = ref([
  {
    label: t("common.print"),
    icon: "pi pi-fw pi-print",
    command: () => togglePrintModal(),
    disabled: selectedInvoices.value.length === 0,
  },
]);

watch(selectedInvoices, () => {
  menuModel.value[0].disabled = selectedInvoices.value.length === 0;
});

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};
const filters = ref({
  "orderedBy.customerNumber": { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const filteredInvoices = computed<Invoice[]>(() => {
  if (invoices.value.length === 0) {
    return [];
  }
  return invoices.value.filter((invoice: Invoice) => {
    return (
      invoice.orderNumber.toString().startsWith(query.value.trim()) ||
      invoice.invoiceNumber.toString().startsWith(query.value.trim())
    );
  });
});

watch(filters, (newValue) => {
  if (!newValue["orderedBy.customerNumber"].value) {
    getInvoices();
  }
});

const onCustomerNumberInput = async (customerNumber: string) => {
  try {
    if (customerNumber !== "") {
      await getFilteredInvoices(customerNumber);
    } else {
      await getInvoices();
    }
  } catch {
    loadFailed.value = true;
  }
};

const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  const selectedInvoice = event.data as Invoice;
  selectedInvoices.value.push(selectedInvoice);
  emit("navigateToCreditNote");
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  downloadPdf((event.data as Invoice).id);
};

const downloadPdf = async (id: string) => {
  try {
    const url = await getInvoiceDocumentUrl(id);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.an-error-occured"),
        detail: t("invoice.download-link-missing"),
        closable: true,
      });
    }
  } catch {
    // ignored
  }
};

const focusSearchResult = () => {
  if (invoices.value.length > 0 && invoiceSearchResult.value) {
    invoiceSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

onMounted(async () => {
  await getInvoices();
  nextTick(() => {
    focusSearchResult();
  });
});
</script>

<style scoped lang="scss">
.c-invoice-table {
  :deep(.p-datatable-thead > tr > .text-right > .p-column-header-content > .p-column-title) {
    width: 100%;
    text-align: right;
  }
}
.c-invoice {
  margin: var(--default-content-margin);
}
.c-invoice .card {
  overflow: auto;
}
:deep(.c-datatable.p-datatable .p-datatable-tbody > tr > td.c-pdf-icon) {
  display: flex;
  justify-content: center;
}

:deep(.c-datatable.p-datatable .p-datatable-thead > tr > th.c-pdf-icon) {
  display: flex;
  justify-content: center;
}

.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
