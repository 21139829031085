import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { financeApi } from "./FinanceApi";
import { FinanceNumberSeries } from "@/number-series/model/finance/FinanceNumberSeries";

export function useFinanceService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getFinanceNumberSeries = async (): Promise<FinanceNumberSeries[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await financeApi.getAllNumberSeries(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateFinanceNumberSeries = async (numberSeries: FinanceNumberSeries): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await financeApi.updateNumberSeries(authHeaders, numberSeries);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getFinanceNumberSeries,
    updateFinanceNumberSeries,
  };
}
