import { CreditNoteClient } from "./CreditNoteClient";
import { CreditNoteContact } from "./CreditNoteContact";
import { CreditNoteCustomer } from "./CreditNoteCustomer";
import { CreditNoteLine } from "./CreditNoteLine";

export class CreditNote {
  id = "";
  companyId = "";
  orderNumber = 0;
  creditNoteNumber = 0;
  creditReference = "";
  creditComment = "";
  currencyIso = "";
  creditNoteLines: CreditNoteLine[] = [];
  orderedBy: CreditNoteCustomer = new CreditNoteCustomer();
  sumTotalLines = 0;
  shippingPrice = 0;
  totalSum = 0;
  totalVatAmount = 0;
  totalSumIncVat = 0;
  kid = "";
  creditNoteDate = new Date().toISOString();
  client: CreditNoteClient = new CreditNoteClient();
  contactPerson = new CreditNoteContact();
}
