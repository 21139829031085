<template>
  <FloatLabel variant="on">
    <InputText
      id="credit-reference"
      v-model="creditReference"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('creditnote.reference').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="credit-reference"
      class="w-full"
      :invalid="val.$error"
      :fluid="true"
      :disabled="disabled"
      :maxlength="500"
      aria-describedby="credit-reference-help"
      autofocus
    />
    <label for="credit-reference" class="c-required">
      {{ t(`creditnote.reference`) }}
    </label>
  </FloatLabel>
  <small v-if="val.$error" class="p-error" data-testid="credit-reference-error">
    {{ val.$errors[0].$message }}
  </small>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";

const { t } = useI18n();

const creditReference = defineModel<string | null>("creditReference", {
  required: true,
});

defineProps<{
  disabled?: boolean;
}>();

const rules = {
  creditReference: { required },
};

const val = useVuelidate(rules, { creditReference });
</script>
