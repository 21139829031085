import { httpClient, AuthHeaders } from "@cumulus/http";
import { AccountSettings } from "../../models/account-settings/AccountSettings";
import { AccountSettingsInfo } from "@/models/account-settings/AccountSettingsInfo";

class AccountSettingsApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/account-settings`
        : `${import.meta.env.VITE_APP_API_URL as string}/account-settings`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<AccountSettingsInfo[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<AccountSettings> {
    return await httpClient(this.uri, authHeaders)
      .get(`/${id}`)
      .then(({ data }) => data);
  }

  public async createDefault(authHeaders: AuthHeaders, clientIds: string[]): Promise<AccountSettingCreatedResponse> {
    const request = {
      clientIds: clientIds,
    };
    return await httpClient(this.uri, authHeaders)
      .post("/default", request)
      .then(({ data }) => data);
  }

  public async update(authHeaders: AuthHeaders, accountSettings: AccountSettings): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("", accountSettings);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(`/${id}`);
  }
}
export const accountSettingsApi = new AccountSettingsApi();

interface AccountSettingCreatedResponse {
  accountSettingsId: string;
  url: string;
}
