<template>
  <div class="c-number-series-list">
    <DataTable
      :value="numberSeries"
      dataKey="type"
      :autoLayout="true"
      responsiveLayout="scroll"
      selectionMode="single"
      :loading="loading"
      stripedRows
      :paginator="false"
      :rows="3"
      class="c-datatable"
      data-testid="c-number-series-list"
      resizableColumns
      columnResizeMode="expand"
      showGridlines
      @row-dblclick="onRowDblClick"
    >
      <Column
        :header="!numberSeries[0]?.type ? '' : t(`number-series.column.type-${numberSeries[0]?.type}`)"
        headerClass="w-12"
      >
        <template #body="{ data }">
          <span :data-testid="`type-${data.type}`">{{ !data?.type ? "" : t(`number-series.${data.type}`) }}</span>
        </template>
      </Column>

      <Column :header="t(`number-series.column.start-number`)" headerClass="w-8" bodyClass="text-right">
        <template #body="{ data }">
          <span :data-testid="`start-number-${data?.type?.toLowerCase()}`">{{ data.startNumber }}</span>
        </template>
      </Column>

      <Column :header="t(`number-series.column.last-number`)" headerClass="w-12" bodyClass="text-right">
        <template #body="{ data }">
          <span :data-testid="`last-number-${data?.type?.toLowerCase()}`">{{ data.lastUsedNumber }}</span>
        </template>
      </Column>

      <Column headerClass="w-12" bodyClass="text-right max-w-16">
        <template #body="{ data }">
          <PrimeButton type="button" :data-testid="`edit-${data.type}`" text @click="editNumberSeries(data)">
            <i class="pi pi-pencil"></i>
          </PrimeButton>
        </template>
      </Column>

      <template #empty>{{ t("number-series.empty-list") }} </template>
      <template #loading>
        <div>
          <div class="flex justify-center">
            <ProgressSpinner v-if="loading" aria-label="Loading" class="c-number-spinner" />
          </div>
          <div>{{ t("number-series.loading-list") }}</div>
        </div>
      </template>
    </DataTable>

    <DialogEditNumberSeries
      v-if="showEditDialog && dialogNumberSeries"
      v-model:showDialog="showEditDialog"
      :numberSeries="dialogNumberSeries"
      @saveNumberSeries="emit('saveNumberSeries', $event)"
    >
    </DialogEditNumberSeries>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import DialogEditNumberSeries from "./DialogEditNumberSeries.vue";

const { t } = useI18n();

const props = defineProps<{
  numberSeries: NumberSeries[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (e: "saveNumberSeries", value: NumberSeries): void;
}>();

const numberSeries = computed<NumberSeries[]>(() => props.numberSeries);

const dialogNumberSeries = ref<NumberSeries>();

const showEditDialog = ref(false);

const editNumberSeries = (value: NumberSeries) => {
  dialogNumberSeries.value = value;
  showEditDialog.value = true;
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editNumberSeries(event.data);
};
</script>
