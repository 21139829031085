<template>
  <label class="c-customer-info-label">{{ t("creditnote.customer.customer-info") }}</label>
  <table>
    <tbody>
      <tr>
        <td v-if="customer.id" class="w-full text-sm">
          <span data-testid="customer-name">{{ customer.customerName }}</span> /
          <span data-testid="customer-number">{{ customer.customerNumber }}</span>
        </td>
        <td v-else class="w-full text-sm">
          <span>
            {{ t(`creditnote.customer.no-customer-selected`) }}
          </span>
        </td>
      </tr>
      <tr>
        <td class="w-full text-sm whitespace-nowrap overflow-hidden text-ellipsis" data-testid="customer-address">
          <span v-for="(line, index) in customer.address.addressLines" :key="line">
            {{ index > 0 ? ", " : "" }}{{ line }}</span
          >
        </td>
      </tr>
      <tr>
        <td class="w-full text-sm">
          <span data-testid="customer-postal-code" class="mr-1">{{ customer.address.postalCode }}</span>
          <span data-testid="customer-postal-city">{{ customer.address.city }}</span>
        </td>
      </tr>
      <tr>
        <td v-if="customer.phoneNumber" class="w-full text-sm">
          <span>{{ t("creditnote.customer.phone-number") + ": " }}</span
          ><span data-testid="customer-phone-number">{{ customer.phoneNumber }}</span>
        </td>
      </tr>
      <tr>
        <td v-if="customer.email" class="w-full text-sm">
          <span>{{ t("creditnote.customer.email") + ": " }}</span>
          <span data-testid="customer-email">{{ customer.email }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <small v-if="val.customer.id.$error" class="p-error">{{ val.customer.id.$errors[0].$message }}</small>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CreditNoteCustomer } from "@/models/credit-note/CreditNoteCustomer";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

const props = defineProps<{
  customer: CreditNoteCustomer;
}>();

const { t } = useI18n();

const rules = {
  customer: {
    id: {
      required: helpers.withMessage(t("creditnote.customer.required"), required),
    },
  },
};

const val = useVuelidate(rules, props);
</script>

<style lang="scss" scoped>
.c-customer-info-label {
  color: var(--p-sky-600);
  font-size: 0.9rem;
  padding-top: 2.35rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid var(--p-tabs-tab-active-color);
  margin-bottom: 0.6rem;
  width: 100%;
  display: block;
}
</style>
