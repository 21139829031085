<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        data-testid="btn-cancel"
        size="small"
        variant="text"
        @click="router.push({ name: 'invoice' })"
      >
        <i class="pi pi-arrow-left"></i>
        <span class="px-2">{{ t("creditnote.cancel") }}</span>
      </Button>
    </div>
    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>
    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <div v-tooltip.bottom="saveButtonTooltip">
        <Button
          id="btn-save"
          class="c-circular-button"
          data-testid="btn-save"
          :disabled="showReturnStockDialog !== DialogState.Closed"
          @click="onClickSaveButton"
        >
          <i class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("creditnote.save") }}</span>
        </Button>
      </div>
    </div>
  </div>
  <div v-if="loading" class="c-overlay">
    <div class="c-spinner-container">
      <ProgressSpinner />
    </div>
  </div>

  <div class="c-credit-note">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="grid grid-cols-12 gap-8">
                <div class="col-span-12 xl:col-span-6">
                  <div class="col-span-12 mb-4">
                    <SelectCustomer
                      :selected-customer-name="currentCreditNote.orderedBy.customerName"
                      @customer-selected="onSearchCustomerSelected"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactPerson
                      v-model:contact-person="currentCreditNote.contactPerson"
                      :customer-contacts="customerContacts"
                      :customer-type="customerType"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactEmail v-model:email="currentCreditNote.contactPerson.email" />
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-6">
                  <CustomerInfo :customer="currentCreditNote.orderedBy" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="formgrid grid grid-cols-12 gap-4">
                <div class="field col-span-12">
                  <CreditReference v-model:credit-reference="currentCreditNote.creditReference" />
                </div>
                <div class="field col-span-12">
                  <Comment v-model:comment="currentCreditNote.creditComment" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-12">
        <Card>
          <template #content>
            <div>
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12">
                  <CreditableRows :creditable-rows="creditableRows" />
                </div>
                <div class="col-span-12">
                  <AddCreditNoteLine
                    :selected-customer-number="currentCreditNote.orderedBy.customerNumber"
                    :ordered-by-id="currentCreditNote.orderedBy.id"
                    :creditable-rows="creditableRows"
                    @add-without-reference-clicked="creditNoteCreateStore.addNoReferenceLine"
                  />
                  <CreditNoteFooter :total-sum="totalSumToCredit" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <DecisionDialog
    v-model:visible="shouldShowAskToOpenReturnStockDialog"
    @return-inventory-clicked="openReturnStockDialog"
    @save-credit-note-clicked="saveCreditNote"
  />

  <ReturnToStockDialog
    v-model:visible="shouldShowReturnStockDialog"
    :new-credit-note="newCreditNote"
    :creditable-rows="creditableRowsViewModel"
    @save-credit-note="saveCreditNote"
  />
</template>

<script setup lang="ts">
import { useCreditNoteCreateStore } from "@/stores/CreditNoteCreateStore";
import { DialogState, useCreditNoteDialogService } from "@/utils/CreditNoteDialogService";
import { NewCreditNote } from "@/models/credit-note/NewCreditNote";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { useClientStore } from "@/stores/ClientStore";
import { useAuth } from "@cumulus/event-bus";
import { CreditNoteLine } from "@/models/credit-note/CreditNoteLine";
import { useCreditNoteRouteService } from "@/api/credit-note/CreditNoteRouteService";
import useValidate from "@vuelidate/core";
import { type ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";
import { type SearchCustomer } from "@/models/search/customer/SearchCustomer";
import { CreditableRowViewModel } from "@/models/credit-note/view-model/CreditableRowViewModel";

const { t } = useI18n();
const router = useRouter();
const { getUser } = useAuth();
const creditNoteCreateStore = useCreditNoteCreateStore();
const { getById } = useClientStore();
const toast = useCumulusToast(useToast());
const val = useValidate();
const {
  showReturnStockDialog,
  shouldShowAskToOpenReturnStockDialog,
  shouldShowReturnStockDialog,
  askToOpenReturnStockDialog,
  openReturnStockDialog,
} = useCreditNoteDialogService();

const { currentCreditNote, customerContacts, customerType, creditableRows, isCreditNoteChanged } =
  storeToRefs(creditNoteCreateStore);
const { routeToCreditNoteList } = useCreditNoteRouteService(creditNoteCreateStore.clearStore);
const creditableRowsViewModel = computed(() => creditableRows.value as CreditableRowViewModel[]);

const newCreditNote = ref<NewCreditNote>(new NewCreditNote());
const loading = ref<boolean>(false);

const totalSumToCredit = computed<number>(() =>
  creditableRows.value.filter((credit) => credit.included).reduce((sum, row) => sum + row.sumToCredit, 0),
);

onMounted(async () => {
  try {
    loading.value = true;
    const user = await getUser();
    const clientId = user.getClient().id;

    const client = await getById(clientId);

    creditNoteCreateStore.setClientInformation(client);
    creditNoteCreateStore.setInitialCreditNote();
  } finally {
    loading.value = false;
  }
});

const saveCreditNote = async (returnProducts?: ReturnProductInfo[]) => {
  await creditNoteCreateStore.createCreditNote(returnProducts);
  routeToCreditNoteList();
};

const hasOnlyNoReferenceLines = computed(() => {
  return creditableRows.value.every((row) => row.isNoReferenceLine);
});

const onSearchCustomerSelected = async (customer: SearchCustomer) => {
  try {
    loading.value = true;
    await creditNoteCreateStore.setCustomerFromSearch(customer);
  } finally {
    loading.value = false;
  }
};

const onClickSaveButton = async () => {
  const add = (x: number, y: number) => {
    return x + y;
  };

  const creditNoteLines = CreditNoteLine.mapFromCreditRows(creditableRows.value);

  currentCreditNote.value.creditNoteLines = creditNoteLines;
  currentCreditNote.value.sumTotalLines = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalSum = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalVatAmount = creditNoteLines.map((x) => x.sumLineVatAmount).reduce(add, 0);
  currentCreditNote.value.totalSumIncVat = creditNoteLines.map((x) => x.sumLineIncVat).reduce(add, 0);

  if (currentCreditNote.value.creditNoteLines.length === 0) {
    toast.add({
      severity: "warn",
      summary: t("creditnote.add.toast.warn"),
    });
    return;
  }

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  if (hasOnlyNoReferenceLines.value) {
    await saveCreditNote();
  } else {
    askToOpenReturnStockDialog();
  }
};

const saveButtonTooltip = computed(() => {
  return isCreditNoteChanged.value
    ? { value: "" }
    : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

useUnsavedChanges(isCreditNoteChanged);
</script>

<style scoped lang="scss">
.c-credit-note {
  margin: var(--default-content-margin);
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
