<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-4"
          data-testId="btn-save"
          :disabled="showReturnStockDialog !== DialogState.Closed"
          @click="onClickSaveButton"
        >
          <i class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("creditnote.save") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button" data-testId="btn-cancel" @click="router.push({ name: 'invoice' })">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <div class="c-overlay" v-if="loading">
    <div class="c-spinner-container">
      <ProgressSpinner />
    </div>
  </div>

  <div class="c-credit-note">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 xl:col-span-6">
                  <div class="col-span-12">
                    <ContactPerson
                      v-model:contactPerson="currentCreditNote.contactPerson"
                      :customerContacts="customer.businessCustomer?.contacts ?? []"
                      :customerType="customer.customerType"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactEmail v-model:email="currentCreditNote.contactPerson.email" />
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-6">
                  <CustomerInfo :customer="currentCreditNote.orderedBy" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="formgrid grid grid-cols-12 gap-4">
                <div class="field col-span-12">
                  <CreditReference v-model:creditReference="currentCreditNote.creditReference" />
                </div>
                <div class="field col-span-12">
                  <Comment v-model:comment="currentCreditNote.creditComment" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-12">
        <Card>
          <template #content>
            <div>
              <CreditableRows :creditableRows="creditableRows" @initializeValuesToCredit="initializeValuesToCredit" />
              <CreditNoteFooter :totalSum="totalSumToCredit" />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
  <DecisionDialog
    v-model:visible="shouldShowAskToOpenReturnStockDialog"
    @returnInventoryClicked="openReturnStockDialog"
    @saveCreditNoteClicked="saveCreditNote"
  />

  <ReturnToStockDialog
    v-model:visible="shouldShowReturnStockDialog"
    :creditableRows="creditableRows as CreditableRowViewModel[]"
    :newCreditNote="newCreditNote"
    @saveCreditNote="saveCreditNote"
  />

  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="stayOnPage"
    @discardClicked="routeToInvoiceList"
    @saveClicked="onClickSaveButton"
  >
    <template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template></UnsavedChangesDialog
  >
</template>

<script setup lang="ts">
import { useInvoiceStore } from "@/stores/InvoiceStore";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { CreditableRow } from "../models/credit-note/CreditableRow";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { NewCreditNote } from "@/models/credit-note/NewCreditNote";
import CreditableRows from "@/components/credit-note/CreditableRows.vue";
import CustomerInfo from "@/components/credit-note/CustomerInfo.vue";
import Comment from "@/components/credit-note/Comment.vue";
import CreditReference from "@/components/credit-note/CreditReference.vue";
import ContactPerson from "@/components/credit-note/ContactPerson.vue";
import { Invoice } from "@/models/invoice/Invoice";
import DecisionDialog from "@/components/credit-note/DecisionDialog.vue";
import ReturnToStockDialog from "@/components/credit-note/ReturnToStockDialog.vue";
import ContactEmail from "@/components/credit-note/ContactEmail.vue";
import { UnsavedChangesDialog } from "@cumulus/components";
import { useCreditNoteRouteService } from "@/api/credit-note/CreditNoteRouteService";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash.clonedeep";
import { useCustomerService } from "@/api/customer/CustomerService";
import { Customer } from "@/models/customer/Customer";
import { useCreditFromInvoicesStore } from "@/stores/CreditNoteFromInvoicesStore";
import { DialogState, useCreditNoteDialogService } from "@/utils/CreditNoteDialogService";
import { CreditNoteLine } from "@/models/credit-note/CreditNoteLine";
import CreditNoteFooter from "@/components/credit-note/CreditNoteFooter.vue";
import useValidate from "@vuelidate/core";
import { ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";
import { CreditableRowViewModel } from "@/models/credit-note/view-model/CreditableRowViewModel";

const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const val = useValidate();
const { t } = useI18n();

const customer = ref<Customer>(new Customer());
const invoices = ref<Invoice[]>([]);
const creditableRows = ref<CreditableRow[]>([]);
const newCreditNote = ref<NewCreditNote>(new NewCreditNote());
const loading = ref(false);

const creditStore = useCreditFromInvoicesStore();
const invoiceStore = useInvoiceStore();
const { currentCreditNote, initialCreditNote, isCreditNoteChanged } = storeToRefs(creditStore);
const { unsavedChangesDialogVisible, stayOnPage, routeToInvoiceList } = useCreditNoteRouteService(
  isCreditNoteChanged,
  creditStore.clearStore
);
const { getCustomerById } = useCustomerService();
const {
  showReturnStockDialog,
  shouldShowAskToOpenReturnStockDialog,
  shouldShowReturnStockDialog,
  askToOpenReturnStockDialog,
  openReturnStockDialog,
} = useCreditNoteDialogService();

const invoiceIds = computed<string[]>(() => {
  const ids: string | string[] | undefined = route.query.invoiceIds as unknown as undefined;
  return [].concat(ids ?? []);
});

const saveCreditNote = async (returnProducts?: ReturnProductInfo[]) => {
  await creditStore.createCreditNote(returnProducts);
  router.push({ name: "invoice" });
};

const initializeValuesToCredit = (row: CreditableRow) => {
  const previouslyCredited = invoiceStore.creditTotals
    .flatMap((t) => t.creditedInvoiceLines.map((l) => ({ ...l, invoiceId: t.id })))
    .find((t) => t.invoiceId === row.invoiceId && t.positionNumber === row.positionNumber);

  row.quantityToCredit = row.quantity ? row.quantity - (previouslyCredited?.quantity ?? 0) : null;
  row.sumToCredit = row.invoicedSum - (previouslyCredited?.sumLine ?? 0);
  row.listPriceToCredit = row.sumToCredit / (row.quantityToCredit || 1);

  if (previouslyCredited) {
    row.previouslyCreditedQuantity = previouslyCredited.quantity;
    row.previouslyCreditedSum = previouslyCredited.sumLine;

    if ((row.quantityToCredit ?? 0) * (row.listPriceToCredit ?? 0) > row.sumToCredit + 0.01) {
      row.quantityToCredit = 0;
      row.listPriceToCredit = 0;
    }
  }

  if ((row.quantityToCredit ?? 0) === 0 && row.sumToCredit === 0) {
    row.included = false;
  }
};

const totalSumToCredit = computed<number>(() =>
  creditableRows.value.filter((credit) => credit.included).reduce((sum, row) => sum + row.sumToCredit, 0)
);

const initializeCreditableRows = () => {
  const uncreditedInvoices = invoiceStore.invoices.filter((invoice) => {
    return !creditableRows.value.some((row) => row.invoiceId === invoice.id);
  });

  for (const i of uncreditedInvoices.filter((i) => invoiceIds.value.includes(i.id))) {
    for (const row of i.invoiceLines) {
      const newCreditableRow = CreditableRow.createFromInvoiceAndLine(i, row);

      initializeValuesToCredit(newCreditableRow);
      creditableRows.value.push(newCreditableRow);
    }

    const freightRow = CreditableRow.createFreightRow(i);

    initializeValuesToCredit(freightRow);
    creditableRows.value.push(freightRow);
  }
};

onMounted(async () => {
  try {
    loading.value = true;

    await Promise.all([invoiceStore.getInvoices(), invoiceStore.getCreditTotals(invoiceIds.value)]);
    invoices.value = invoiceStore.invoices.filter((invoice) => invoiceIds.value.includes(invoice.id));

    customer.value = await getCustomerById(invoices.value[0].orderedBy.id);
    creditStore.setCustomerInfo(customer.value);
    initializeCreditableRows();

    currentCreditNote.value.currencyIso = invoices.value[0].currencyIso;
    currentCreditNote.value.kid = invoices.value[0].kid;
    currentCreditNote.value.orderedBy = invoices.value[0].orderedBy;
    currentCreditNote.value.client = {
      clientId: invoices.value[0].client.clientId,
      name: invoices.value[0].client.name,
      businessNumber: invoices.value[0].client.businessNumber,
      phoneNumber: invoices.value[0].client.phoneNumber,
      email: invoices.value[0].client.email,
      address: invoices.value[0].client.address,
      accountNumber: invoices.value[0].accountNumber,
      bankName: invoices.value[0].bankName,
      logoBase64: invoices.value[0].client.logoBase64,
    };
    currentCreditNote.value.shippingPrice = invoices.value[0].shippingPrice;
    currentCreditNote.value.sumTotalLines = invoices.value[0].sumTotalLines;
    initialCreditNote.value = cloneDeep(currentCreditNote.value);
  } finally {
    loading.value = false;
  }
});

const onClickSaveButton = async () => {
  if (invoices.value.length === 0) {
    toast.add({
      severity: "error",
      summary: t("creditnote.add.toast.no-invoices"),
    });
    return;
  }

  if (new Set(invoices.value.map((i) => i.currencyIso)).size > 1) {
    toast.add({
      severity: "error",
      summary: t("creditnote.add.toast.too-many-currencies"),
    });
    return;
  }

  if (new Set(invoices.value.map((i) => i.client.clientId)).size > 1) {
    toast.add({
      severity: "error",
      summary: t("creditnote.add.toast.too-many-clients"),
    });
    return;
  }

  const add = (x: number, y: number) => {
    return x + y;
  };

  const creditNoteLines = CreditNoteLine.mapFromCreditRows(creditableRows.value);

  currentCreditNote.value.creditNoteLines = creditNoteLines;
  currentCreditNote.value.sumTotalLines = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalSum = creditNoteLines.map((x) => x.sumLine).reduce(add, 0);
  currentCreditNote.value.totalVatAmount = creditNoteLines.map((x) => x.sumLineVatAmount).reduce(add, 0);
  currentCreditNote.value.totalSumIncVat = creditNoteLines.map((x) => x.sumLineIncVat).reduce(add, 0);

  if (currentCreditNote.value.creditNoteLines.length === 0) {
    toast.add({
      severity: "warn",
      summary: t("creditnote.add.toast.warn"),
    });
    return;
  }

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  askToOpenReturnStockDialog();
};
</script>

<style scoped lang="scss">
.c-bottom-line {
  display: flex;
  justify-content: end;
}

.c-credit-note {
  margin: var(--default-content-margin);
}

:deep(.p-inputtext) {
  &:enabled:focus {
    border: var(--floating-input-border-on-focus);
    box-shadow: none;
  }
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
