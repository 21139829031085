<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <div
        v-tooltip.bottom="selectedInvoices.length === 0 ? { value: t('creditnote.create-from-invoices-disabled') } : ''"
        class="inline-block"
      >
        <Button
          class="mr-4"
          :disabled="selectedInvoices.length === 0"
          data-testId="btn-create-credit-note"
          variant="text"
          size="small"
          @click="emit('navigateToCreditNote')"
        >
          <i class="pi pi-plus c-success-button c-circular-icon text-xs rounded-3xl p-2 text-white"></i>
          <span class="font-normal">{{ t("creditnote.create-from-invoices") }}</span>
        </Button>
      </div>

      <Button variant="text" size="small" data-testId="btn-navigate" @click="openCreditNotesList">
        <i class="pi pi-list c-default-button c-circular-icon text-xs rounded-3xl p-2 text-white"></i>
        <span class="font-normal">{{ t("creditnote.header") }}</span>
      </Button>
    </div>

    <div>
      <Button
        v-tooltip.bottom="{
          value: t('creditnote.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isSortingVisible }"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('creditnote.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('creditnote.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import { useAuth, User } from "@cumulus/event-bus";
import { Invoice } from "@/models/invoice/Invoice";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

const user = ref<User>(new User());
const { getUser } = useAuth();
onMounted(async () => {
  user.value = await getUser();
});

const props = defineProps<{
  isSortingVisible: boolean;
  selectedInvoices: Invoice[];
}>();

const emit = defineEmits<{
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
  (e: "navigateToCreditNote"): void;
}>();

const openCreditNotesList = () => {
  router.push({ name: "credit-note-list" });
};

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });
</script>
