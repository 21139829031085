<template>
  <PrimeDialog
    v-model:visible="visibleDialog"
    :header="t('account-settings.add-account-settings')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-2/12"
    data-testid="c-account-settings-add-account-settings-dialog"
  >
    <SelectClients v-model:selectedClientIds="selectedClientIds" :clients="clients" />
    <template #footer>
      <div class="flex justify-end">
        <PrimeButton class="c-circular-button mr-4" data-testid="btn-cancel" @click="visibleDialog = false">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button mr-4"
          data-testid="c-account-settings-create-new-button"
          :disabled="selectedClientIds.length === 0"
          @click="onAddNewAccountSettings"
        >
          <ProgressSpinner v-if="saving" class="c-spinner" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.add") }}</span>
        </PrimeButton>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { Client } from "@/models/client/Client";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import SelectClients from "./SelectClients.vue";
import { useAccountSettingsService } from "@/api/account-settings/AccountSettingsService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";

defineProps<{
  clients: Client[];
}>();

const visibleDialog = defineModel<boolean>("visibleDialog", {
  required: true,
});

const emit = defineEmits<{
  (e: "accountSettingsCreated", value: string): void;
}>();

const selectedClientIds = ref<string[]>([]);
const saving = ref(false);

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { createDefaultAccountSettings } = useAccountSettingsService();

const onAddNewAccountSettings = async () => {
  saving.value = true;
  try {
    const id = await createDefaultAccountSettings(selectedClientIds.value);

    emit("accountSettingsCreated", id);
    toast.add({
      severity: "success",
      summary: t("account-settings.account-settings-added-summary"),
      detail: t("account-settings.account-settings-added-details"),
      life: 5000,
    });
    visibleDialog.value = false;
    saving.value = false;
  } finally {
    saving.value = false;
  }
};
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
</style>
