import { httpClient, AuthHeaders } from "@cumulus/http";
import { i18n } from "@/locales/i18n";
import { Country } from "@/models/country/Country";
import { WritableComputedRef } from "vue";

class CountryApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Country[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/countries/bylocale/${(i18n.global.locale as WritableComputedRef<string>).value}`)
      .then(({ data }) => data);
  }
}

const countryApi = new CountryApi();
export { countryApi };
