<template>
  <div class="flex flex-col">
    <div class="flex items-center mb-4">
      <div class="grow mr-4 max-w-80">
        <IconField iconPosition="left">
          <InputIcon class="pi pi-search" />
          <InputText
            id="invoiceSearchInput"
            ref="searchInputRef"
            v-model="query"
            v-debounce:200="search"
            :placeholder="t('creditnote.product-search')"
            autocomplete="off"
            data-testid="product-search-input"
            class="w-full"
          />
        </IconField>
      </div>
      <div>
        <PrimeButton
          :label="t('creditnote.without-product-reference')"
          data-testid="without-reference-btn"
          class="ml-4"
          @click="emit('addWithoutReferenceClicked')"
        />
      </div>
    </div>
    <div class="w-full">
      <ProductList
        :creditableRows="creditableRows"
        :products="products"
        :loading="loading"
        :totalHits="totalHits"
        :page="page"
        :sortField="sortField"
        :sortOrder="sortOrder"
        :pageSize="pageSize"
        :class="{ hidden: !showResults }"
        @creditableRowAdded="onCreditedRowAdded"
        @update:sortOrder="onSortOrderUpdated"
        @update:sortField="onSortFieldUpdated"
        @update:page="onPageUpdated"
        @update:pageSize="onPageSizeUpdated"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import ProductList from "./ProductList.vue";
import { ProductSearchFilters } from "@/models/search/product/ProductSearchFilters";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import { useProductSearchService } from "@/api/search/ProductSearchService";
import { SearchProduct } from "@/models/search/product/SearchProduct";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { useCreditNoteCreateStore } from "@/stores/CreditNoteCreateStore";

defineProps<{
  creditableRows: CreditableRow[];
}>();

const creditNoteCreateStore = useCreditNoteCreateStore();

const query = ref<string>("");
const showResults = ref<boolean>(false);
const loading = ref<boolean>(false);
const products = ref<SearchProduct[]>([]);
const totalHits = ref<number>(0);
const sortField = ref<string>("");
const sortOrder = ref<number>(1);
const page = ref<number>(1);
const pageSize = ref<number>(50);

const { t } = useI18n();
const { productSearch } = useProductSearchService();

const emit = defineEmits<{
  (e: "addWithoutReferenceClicked"): void;
}>();

const onPageUpdated = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onPageSizeUpdated = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onSortOrderUpdated = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onSortFieldUpdated = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onCreditedRowAdded = (row: CreditableRow) => {
  creditNoteCreateStore.addCreditableRowLineFromProduct(row);
};

const search = async () => {
  if (query.value.length < 1) {
    showResults.value = false;
    return;
  }

  try {
    loading.value = true;
    showResults.value = true;

    const filters = new ProductSearchFilters();
    const request = new ProductSearchRequest(
      query.value,
      page.value,
      pageSize.value,
      sortField.value,
      sortOrder.value === 1 ? "asc" : "desc",
      filters,
    );

    const response = await productSearch(request);
    products.value = response.products;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};
</script>
