import { CreditNoteClient } from "./CreditNoteClient";
import { CreditNoteCustomer } from "./CreditNoteCustomer";
import { CreditNoteLine } from "./CreditNoteLine";
import { CreditNoteContact } from "./CreditNoteContact";
import { CreditNote } from "./CreditNote";

export class NewCreditNote {
  creditReference = "";
  creditComment = "";
  currencyIso = "";
  creditNoteLines: CreditNoteLine[] = [];
  orderedBy = new CreditNoteCustomer();
  client = new CreditNoteClient();
  sumTotalLines = 0;
  shippingPrice = 0;
  fees = 0;
  totalSum = 0;
  totalVatAmount = 0;
  totalSumIncVat = 0;
  creditNoteDate = new Date().toISOString();
  paymentDueDate = new Date().toISOString();
  contactPerson = new CreditNoteContact();

  public static createFromCreditNote(creditNote: CreditNote): NewCreditNote {
    const newCreditNote = new NewCreditNote();
    newCreditNote.creditReference = creditNote.creditReference;
    newCreditNote.creditComment = creditNote.creditComment;
    newCreditNote.currencyIso = creditNote.currencyIso;
    newCreditNote.creditNoteLines = creditNote.creditNoteLines;
    newCreditNote.orderedBy = creditNote.orderedBy;
    newCreditNote.client = creditNote.client;
    newCreditNote.sumTotalLines = creditNote.sumTotalLines;
    newCreditNote.shippingPrice = creditNote.shippingPrice;
    newCreditNote.totalSum = creditNote.totalSum;
    newCreditNote.totalVatAmount = creditNote.totalVatAmount;
    newCreditNote.totalSumIncVat = creditNote.totalSumIncVat;
    newCreditNote.creditNoteDate = creditNote.creditNoteDate;
    newCreditNote.contactPerson = creditNote.contactPerson;

    return newCreditNote;
  }
}
