<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12 xl:col-span-2">
      <IconField icon-position="left">
        <InputIcon class="pi pi-search" />
        <InputText
          id="invoiceSearchInput"
          ref="searchInputRef"
          v-model="query"
          v-debounce:200="search"
          :placeholder="t('creditnote.invoice-search')"
          autocomplete="off"
          data-testid="invoice-search-input"
          class="w-full"
        />
      </IconField>
    </div>
    <div class="col-span-12">
      <PrevioslyCreditedInvoicesList
        :creditable-rows="creditableRows"
        :previosly-credited-invoices="previoslyCreditedInvoices"
        :loading="loading"
        :class="{ hidden: !showResults }"
        @creditable-row-added="onCreditedRowAdded"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PrevioslyCreditedInvoicesList from "./PrevioslyCreditedInvoicesList.vue";
import { SearchInvoice } from "@/models/search/invoice/SearchInvoice";
import { InvoiceSearchRequest } from "@/models/search/invoice/InvoiceSearchRequest";
import { useInvoiceSearchService } from "@/api/search/InvoiceSearchService";
import { useI18n } from "vue-i18n";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { type CreditTotal } from "@/models/credit-note/CreditTotals";
import { InvoiceSearchFilters } from "@/models/search/invoice/InvoiceSearchFilters";
import { useCreditNoteCreateStore } from "@/stores/CreditNoteCreateStore";
import { watch } from "vue";
import { useCreditNoteService } from "@/api/credit-note/CreditNoteService";

const props = defineProps<{
  selectedCustomerNumber: string;
  orderedById: string;
  creditableRows: CreditableRow[];
}>();

const loading = ref<boolean>(false);
const query = ref<string>("");
const invoices = ref<SearchInvoice[]>([]);
const creditTotals = ref<CreditTotal[]>([]);
const totalHits = ref<number>(0);
const previoslyCreditedInvoices = ref<CreditableRow[]>([]);
const showResults = ref<boolean>(false);

const creditNoteCreateStore = useCreditNoteCreateStore();
const { invoiceSearch } = useInvoiceSearchService();
const { getCreditTotals } = useCreditNoteService();
const { t } = useI18n();

watch(
  () => props.selectedCustomerNumber,
  () => {
    query.value = "";
    showResults.value = false;
    previoslyCreditedInvoices.value = [];
  },
);

const search = async () => {
  if (query.value.length < 1) {
    showResults.value = false;
    return;
  }

  try {
    loading.value = true;
    showResults.value = true;

    const filters = new InvoiceSearchFilters();
    filters.customerNumbers = [props.selectedCustomerNumber];
    const request = new InvoiceSearchRequest(query.value, filters);

    const response = await invoiceSearch(request);
    invoices.value = response.invoices;
    totalHits.value = response.totalHits;

    const invoiceIds = invoices.value.map((i) => i.id);
    creditTotals.value = await getCreditTotals(invoiceIds);
    initializeCreditableRows(invoices.value, creditTotals.value);
  } finally {
    loading.value = false;
  }
};

const onCreditedRowAdded = (row: CreditableRow) => {
  creditNoteCreateStore.addCreditableRowLineFromInvoice(row);
  if (props.orderedById == "") {
    query.value = "";
    showResults.value = false;
  }
};

const initializeCreditableRows = (invoices: SearchInvoice[], creditTotals: CreditTotal[]) => {
  previoslyCreditedInvoices.value = [];

  const uncreditedInvoices = invoices.filter((invoice) => {
    return !previoslyCreditedInvoices.value.some((row) => row.invoiceId === invoice.id);
  });

  for (const i of uncreditedInvoices) {
    for (const row of i.invoiceLines) {
      const newCreditableRow = CreditableRow.createFromInvoiceAndLine(i, row);

      calculateCreditValues(newCreditableRow, creditTotals);
      previoslyCreditedInvoices.value.push(newCreditableRow);
    }

    const freightRow = CreditableRow.createFreightRow(i);

    calculateCreditValues(freightRow, creditTotals);
    previoslyCreditedInvoices.value.push(freightRow);
  }
};

const calculateCreditValues = (row: CreditableRow, creditTotals: CreditTotal[]) => {
  const previouslyCredited = creditTotals
    .flatMap((t) => t.creditedInvoiceLines.map((l) => ({ ...l, invoiceId: t.id })))
    .find((t) => t.invoiceId === row.invoiceId && t.positionNumber === row.positionNumber);

  const previouslyCreditedInCreditableRows = props.creditableRows.find(
    (r) => r.invoiceId === row.invoiceId && r.positionNumber === row.positionNumber,
  );

  row.quantityToCredit = row.quantity ? row.quantity - (previouslyCredited?.quantity ?? 0) : null;
  row.sumToCredit = row.invoicedSum - (previouslyCredited?.sumLine ?? 0);
  row.listPriceToCredit = row.sumToCredit / (row.quantityToCredit || 1);

  if (previouslyCredited) {
    row.previouslyCreditedQuantity = previouslyCredited.quantity;
    row.previouslyCreditedSum = previouslyCredited.sumLine;

    if ((row.quantityToCredit ?? 0) * (row.listPriceToCredit ?? 0) > row.sumToCredit + 0.01) {
      row.quantityToCredit = 0;
      row.listPriceToCredit = 0;
    }
  }

  if (previouslyCreditedInCreditableRows) {
    row.previouslyCreditedQuantity = +previouslyCreditedInCreditableRows.previouslyCreditedQuantity;
    row.previouslyCreditedSum = +previouslyCreditedInCreditableRows.previouslyCreditedSum;
  }

  if ((row.quantityToCredit ?? 0) === 0 && row.sumToCredit === 0) {
    row.included = false;
  }
};
</script>
