import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { Invoice } from "@/models/invoice/Invoice";
import { invoiceApi } from "./InvoiceApi";

export function useInvoiceService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getInvoices = async (): Promise<Invoice[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await invoiceApi.list(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getInvoiceById = async (id: string): Promise<Invoice> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await invoiceApi.getById(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getFilteredInvoices = async (customerNumber: string): Promise<Invoice[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await invoiceApi.getInvoicesByCustomerNumber(authHeaders, customerNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getInvoices,
    getFilteredInvoices,
    getInvoiceById,
  };
}
