<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="router.back()"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800">{{ t("settings.header") }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div
      id="teleport-product-toolbar"
      class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5"
    ></div>
  </div>

  <div v-if="useTeleport" id="open-picking-navbar-shortcuts">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("settings.header") }}</h1>
    </Teleport>
    <Teleport to="#c-navbar-shortcuts">
      <div class="c-header-icon-container"></div>
    </Teleport>
  </div>
  <div class="c-page-content mt-1 ml-5 pr-1">
    <div>
      <Panel
        id="taxPanel"
        :header="t('settings.tax.header')"
        toggleable
        :collapsed="panelCollapsedTax"
        class="mb-4"
        data-testid="taxPanel"
        :pt:header:on-click="() => (panelCollapsedTax = !panelCollapsedTax)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-tax-panel">
            <TaxList />
          </div>
        </div>
      </Panel>

      <Panel
        id="paymentTermsPanel"
        :header="t('settings.payment-terms.header')"
        toggleable
        :collapsed="panelCollapsedPaymentTerms"
        class="mb-4"
        data-testid="paymentTermsPanel"
        :pt:header:on-click="() => (panelCollapsedPaymentTerms = !panelCollapsedPaymentTerms)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-payment-terms-panel">
            <PaymentTermsList :paginator="false" />
          </div>
        </div>
      </Panel>

      <Panel
        id="accountPlanPanel"
        :header="t('settings.account-plan.header')"
        toggleable
        :collapsed="panelCollapsedAccountPlan"
        class="mb-4"
        data-testid="accountPlanPanel"
        :pt:header:on-click="() => (panelCollapsedAccountPlan = !panelCollapsedAccountPlan)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-account-plan-panel">
            <AccountPlans></AccountPlans>
          </div>
        </div>
      </Panel>

      <Panel
        id="numberSeriesPanel"
        :header="t('settings.number-series.header')"
        toggleable
        :collapsed="panelCollapsedNumberSeries"
        class="mb-4"
        data-testid="numberSeriesPanel"
        :pt:header:on-click="() => (panelCollapsedNumberSeries = !panelCollapsedNumberSeries)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-number-series-panel">
            <NumberSeries></NumberSeries>
          </div>
        </div>
      </Panel>

      <Panel
        id="currencyPanel"
        :header="t('settings.currency.header')"
        toggleable
        :collapsed="panelCollapsedCurrency"
        class="mb-4"
        data-testid="currencyPanel"
        :pt:header:on-click="() => (panelCollapsedCurrency = !panelCollapsedCurrency)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-currency-panel">
            <CurrencyList />
          </div>
        </div>
      </Panel>

      <Panel
        id="accountSetupPanel"
        :header="t('settings.account-setup.header')"
        toggleable
        :collapsed="panelCollapsedAccountSetup"
        class="mb-4"
        data-testid="accountSetupPanel"
        :pt:header:on-click="() => (panelCollapsedAccountSetup = !panelCollapsedAccountSetup)"
      >
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12" data-testid="c-account-setup-panel">
            <AccountSettingsList />
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import PaymentTermsList from "@/components/payment-term/PaymentTermsList.vue";
import AccountPlans from "@/components/account-plan/AccountPlans.vue";
import NumberSeries from "@/number-series/components/NumberSeries.vue";
import CurrencyList from "@/components/currency/CurrencyList.vue";
import AccountSettingsList from "@/components/account-settings/AccountSettingsList.vue";
import { useClientStore } from "@/stores/ClientStore";
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import TaxList from "@/components/tax/TaxList.vue";
import { useTaxStore } from "@/stores/TaxStore";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";
import { useRouter } from "vue-router";

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
const { t } = useI18n();
const { getClients } = useClientStore();
const { getAccountPlans } = useAccountPlanStore();
const { getTaxes } = useTaxStore();
const { getPaymentTerms } = usePaymentTermStore();
const router = useRouter();

onMounted(() => {
  getClients();
  getAccountPlans();
  getTaxes();
  getPaymentTerms();
});

const panelCollapsedTax = ref(true);
const panelCollapsedPaymentTerms = ref(true);
const panelCollapsedAccountPlan = ref(true);
const panelCollapsedNumberSeries = ref(true);
const panelCollapsedCurrency = ref(true);
const panelCollapsedAccountSetup = ref(true);
</script>

<style scoped lang="scss"></style>
