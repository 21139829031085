<template>
  <PrimeDialog
    v-model:visible="showDialog"
    :header="t(`payment-term.dialog.header-add`)"
    :modal="true"
    class="c-add-dialog"
  >
    <div class="flex col-span-12 md:col-span-8">
      <div class="formgrid grid grid-cols-12 gap-4">
        <div class="field col-span-12">
          <Name v-model:name="paymentTerm.name" />
        </div>

        <div class="field col-span-12">
          <Description v-model:description="paymentTerm.description" />
        </div>

        <div class="field col-span-12">
          <CreditDays v-model:creditDays="paymentTerm.creditDays" />
        </div>
      </div>
    </div>

    <div class="flex col-span-12 md:col-span-4">
      <div class="formgrid grid grid-cols-12 gap-4">
        <div class="field col-span-12">
          <ShortName v-model:shortName="paymentTerm.shortName" />
        </div>

        <div class="field col-span-12">
          <Available
            v-model:forCustomers="paymentTerm.available.forCustomers"
            v-model:forSuppliers="paymentTerm.available.forSuppliers"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="onCancel"
            class="p-button-secondary"
          />
          <PrimeDialog
            data-testid="unsaved-changes-dialog"
            v-model:visible="unsavedChangesDialogVisible"
            :header="t('common.unsaved-changes-header')"
            :style="{ width: '30vw' }"
            position="center"
            :modal="true"
          >
            <p class="p-6">
              {{ t("common.unsaved-changes-text") }}
            </p>
            <template #footer>
              <PrimeButton :label="t('common.cancel')" @click="unsavedChangesDialogVisible = false" text />
              <PrimeButton :label="t('common.discard')" @click="showDialog = false" text />
              <PrimeButton :label="t('common.save-changes')" icon="pi pi-check" @click="create" />
            </template>
          </PrimeDialog>
        </div>
        <div class="flex items-center justify-center">
          <PrimeButton :label="t(`common.add`)" data-testid="create-btn" @click="create" class="p-button-success" />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { NewPaymentTerm } from "@/models/payment-term/NewPaymentTerm";
import Name from "./Name.vue";
import ShortName from "./ShortName.vue";
import Description from "./Description.vue";
import CreditDays from "./CreditDays.vue";
import Available from "./Available.vue";
import isEqual from "lodash.isequal";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";

const paymentTerm = ref(new NewPaymentTerm());
const initialPaymentTerm = ref(new NewPaymentTerm());
const { createPaymentTerm } = usePaymentTermStore();

const { t } = useI18n();

const unsavedChangesDialogVisible = ref();
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const showDialog = defineModel<boolean>("showDialog", {
  required: true,
});

const val = useValidate();
const toast = useCumulusToast(useToast());

const create = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    await createPaymentTerm(paymentTerm.value);

    toast.add({
      severity: "success",
      summary: t("payment-term.toast.add-success.summary"),
      detail: t("payment-term.toast.add-success.detail", { name: paymentTerm.value.name }),
      closable: true,
    });

    showDialog.value = false;
  } catch {
    toast.add({
      severity: "error",
      summary: t("payment-term.toast.error.summary"),
      detail: t("payment-term.toast.error.detail"),
      closable: true,
    });
  }
};

const hasUpdates = computed(() => {
  return !isEqual(paymentTerm.value, initialPaymentTerm.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    showDialog.value = false;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      unsavedChangesDialogVisible.value = false;
      previouslyFocusedInput.value?.focus();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    showDialog.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-add-dialog {
  width: 90vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 45rem;
  }
}
</style>
