<template>
  <div class="flex items-center -mt-2 pb-2 border-b">
    <div class="flex-1 text-color font-semibold leading-6">
      <div>
        <Button class="c-circular-button mr-4" data-testId="btn-add" @click="navigateToCreditNote">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("creditnote.create") }}</span>
        </Button>

        <Button class="c-circular-button" data-testId="btn-add" @click="openInvoiceList">
          <i class="pi pi-list c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("invoice.header") }}</span>
        </Button>
      </div>
    </div>

    <div>
      <Button
        v-tooltip.bottom="{
          value: t('creditnote.toolbar.sorting'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-selected-icon"
        severity="secondary"
        variant="text"
        size="small"
        :class="{ 'c-toolbar-button-active': props.isSortingVisible }"
        @click="emit('openSortList', $event)"
      >
        <i :class="selectedIcon"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('creditnote.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="c-refresh-button"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('refreshList')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
    <div class="ml-2">
      <Button
        v-tooltip.bottom="{
          value: t('creditnote.toolbar.column-chooser'),
          showDelay: 1000,
          hideDelay: 100,
        }"
        data-testid="btn-column-chooser"
        severity="secondary"
        variant="text"
        size="small"
        @click="emit('openColumnChooser', $event)"
      >
        <i class="pi pi-list"></i>
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import { useAuth, User } from "@cumulus/event-bus";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

const user = ref<User>(new User());
const { getUser } = useAuth();

onMounted(async () => {
  user.value = await getUser();
});

const props = defineProps<{
  isSortingVisible: boolean;
}>();

const openInvoiceList = () => {
  router.push({ name: "invoice" });
};

const emit = defineEmits<{
  (e: "openSortList", value: Event): void;
  (e: "refreshList"): void;
  (e: "openColumnChooser", value: Event): void;
}>();

const navigateToCreditNote = () => {
  router.push({ name: "credit-note-create" });
};

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });
</script>
