<template>
  <div v-if="loadFailed" class="c-invoice">
    <Card>
      <template #content>{{ t("invoice.load-failed") }} </template>
    </Card>
  </div>
  <div v-else class="c-invoice">
    <Card class="m-4">
      <template #content>
        <div>
          <InvoiceList
            v-model:selected-invoices="selectedInvoices"
            @navigate-to-credit-note="navigateToCreditNote"
          ></InvoiceList>
        </div>
      </template>
    </Card>
  </div>
  <ConfirmDialog :pt:footer:class="'hidden'" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { Invoice } from "@/models/invoice/Invoice";
import { useRouter } from "vue-router";
import InvoiceList from "./InvoiceList.vue";

const { t } = useI18n();
const loadFailed = ref(false);
const selectedInvoices = ref<Invoice[]>([]);
const router = useRouter();
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const navigateToCreditNote = () => {
  const firstInvoice = selectedInvoices.value[0];
  const firstCustomerNumber = firstInvoice.orderedBy.customerNumber;
  const firstCurrencyIso = firstInvoice.currencyIso;

  const hasDifferentCustomer = selectedInvoices.value.some(
    (invoice) => invoice.orderedBy.customerNumber !== firstCustomerNumber,
  );

  const hasDifferentCurrencyIso = selectedInvoices.value.some((invoice) => invoice.currencyIso !== firstCurrencyIso);

  const errorMessage = "";

  if (errorMessage && !hasDifferentCustomer && !hasDifferentCurrencyIso) {
    confirm.require({
      header: t("common.an-error-occured"),
      group: "resets",
      message: errorMessage,
      icon: "pi pi-exclamation-triangle",
    });
  }

  if (!errorMessage && !hasDifferentCustomer && !hasDifferentCurrencyIso) {
    router.push({
      name: "credit-note-create-from-invoices",
      query: { invoiceIds: selectedInvoices.value.map((x) => x.id) },
    });
  }

  if (hasDifferentCustomer || hasDifferentCurrencyIso) {
    confirm.require({
      header: t("common.an-error-occured"),
      message: hasDifferentCustomer
        ? t("invoice.error-invoice-customer-mismatch")
        : t("invoice.error-invoice-currency-mismatch"),
      icon: "pi pi-exclamation-triangle",
    });
  }
};
</script>
