<template>
  <DataTable
    :value="paymentTermsSettings"
    class="c-datatable"
    responsiveLayout="scroll"
    stripedRows
    data-testid="c-account-settings-payment-terms-table"
  >
    <Column :header="t('account-settings.payment-terms.payment-term')">
      <template #body="{ data, index }">
        <span v-if="data.isDefault" :data-testid="`c-account-settings-payment-terms-name-${index}`">{{
          t("account-settings.standard")
        }}</span>
        <SelectPaymentTerm
          v-else
          v-model:paymentTermId="data.id"
          :paymentTerms="paymentTerms"
          :paymentTermsSettings="paymentTermsSettings"
          :dataTestId="`c-account-settings-payment-terms-name-${index}`"
          :isRequired="true"
        />
      </template>
    </Column>
    <Column field="ledger" :header="t('account-settings.payment-terms.ledger')">
      <template #body="{ data, index }">
        <Select
          v-model="data.ledger"
          :options="ledgerOptions"
          optionLabel="label"
          optionValue="value"
          :placeholder="t('placeholder.select', { property: t('account-settings.payment-terms.ledger').toLowerCase() })"
          class="inputfield w-full"
          :data-testid="`c-account-settings-payment-terms-ledger-${index}`"
          :pt:list:data-testid="`c-account-settings-payment-terms-ledger-${index}-list`"
          @change="onLedgerUpdated($event, data.id)"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.payment-terms.code')" class="max-w-40">
      <template #body="{ data, index }">
        <div v-if="data.ledger === 'LedgerCustomer'" :data-testid="`c-account-settings-payment-terms-code-${index}`">
          <span> {{ t("account-settings.customer-number") }}</span>
        </div>
        <SelectAccountPlan
          v-else
          v-model:accountPlanId="data.accountPlanId"
          :dataTestId="`c-account-settings-payment-terms-account-plan-${index}`"
          :isRequired="true"
        />
      </template>
    </Column>
    <Column>
      <template #header>
        <PrimeButton
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          :disabled="true"
          :aria-label="t('common.delete')"
        />
      </template>

      <template #body="slotProps">
        <PrimeButton
          v-if="!slotProps.data.isDefault"
          icon="pi pi-trash"
          class="p-button-rounded p-button-plain p-button-text"
          :data-testid="`c-account-settings-payment-terms-delete-button-${slotProps.index}`"
          @click="deletePaymentTerm(slotProps.data)"
        />
      </template>
    </Column>

    <template #empty>
      <span>{{ t("account-settings.no-payment-terms-settings") }}</span>
    </template>
  </DataTable>
  <PrimeButton
    class="p-button-text p-button-sm"
    data-testid="c-account-settings-add-payment-term"
    @click="addPaymentTerm"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </PrimeButton>

  <Popover ref="paymentTermOverlay" appendTo="body" class="c-overlay-panel">
    <i class="pi pi-info-circle"></i>
    <span class="pl-2">{{ t("account-settings.no-payment-terms-available") }}</span>
  </Popover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { AccountSettingPaymentTerm } from "@/models/account-settings/AccountSettingPaymentTerm";
import SelectAccountPlan from "./SelectAccountPlan.vue";
import SelectPaymentTerm from "./SelectPaymentTerm.vue";
import { storeToRefs } from "pinia";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";
import { AccountingLedger } from "@/models/account-settings/AccountingLedger";
import { ref } from "vue";
import { NIL as emptyUuid } from "uuid";
import { DropdownChangeEvent } from "primevue/dropdown";

const paymentTermsSettings = defineModel<AccountSettingPaymentTerm[]>("paymentTermsSettings", {
  required: true,
});

const { t } = useI18n();
const { paymentTerms } = storeToRefs(usePaymentTermStore());
const paymentTermOverlay = ref();

const deletePaymentTerm = (paymentTerm: AccountSettingPaymentTerm) => {
  const index = paymentTermsSettings.value.indexOf(paymentTerm);
  paymentTermsSettings.value.splice(index, 1);
};

const addPaymentTerm = (event: Event) => {
  const availablePaymentTerms = paymentTerms.value.filter((paymentTerm) => {
    return !paymentTermsSettings.value.some((paymentTermSetting) => {
      return paymentTermSetting.id === paymentTerm.id;
    });
  });

  if (availablePaymentTerms.length === 0) {
    paymentTermOverlay.value.toggle(event);
    setTimeout(() => {
      paymentTermOverlay.value.hide();
    }, 2000);
    return;
  }

  const emptyAddedPaymentTerm = paymentTermsSettings.value.find((x) => x.id === "");

  if (emptyAddedPaymentTerm) {
    return;
  }

  paymentTermsSettings.value.push(new AccountSettingPaymentTerm());
};

const ledgerOptions = [
  { label: t("account-settings.ledger.ledgercustomer"), value: AccountingLedger.LedgerCustomer },
  { label: t("account-settings.ledger.ledgerpayment"), value: AccountingLedger.LedgerPayment },
  { label: t("account-settings.ledger.recordaspaid"), value: AccountingLedger.RecordAsPaid },
];

const onLedgerUpdated = (event: DropdownChangeEvent, id: string) => {
  const index = paymentTermsSettings.value.findIndex((x) => x.id === id && x.ledger === event.value);

  if (event.value !== AccountingLedger.LedgerCustomer) {
    return;
  } else {
    paymentTermsSettings.value[index].accountPlanId = emptyUuid;
  }
};
</script>
