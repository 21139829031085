<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('economy-transfer.title')" :itemsList="items"></ColumnOptionsMenu>

    <DataTable
      :key="renderKey as unknown as number"
      :value="props.economyTransfers"
      class="c-datatable"
      dataKey="id"
      :autoLayout="true"
      responsiveLayout="scroll"
      selectionMode="single"
      :loading="loading"
      stripedRows
      :sortField="sortField"
      :sortOrder="sortOrder"
      removableSort
      :paginator="true"
      :currentPageReportTemplate="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      data-testid="c-economy-transfer-list"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      :reorderable-columns="true"
      @row-dblclick="onRowDblClick"
      @sort="onSort"
      @page="onPage"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === EconomyTransferLinesColumns.TransferNumber">
            <span>{{ data.transferNumber }}</span>
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.CustomerNumber">
            <span>{{ data.customerNumber }}</span>
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.SupplierNumber">
            <span>{{ data.supplierNumber }}</span>
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.TransferDocumentType">
            <span>{{ t(`economy-transfer.document-type.${data.transferDocumentType.toLowerCase()}`) }}</span>
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.DocumentDate">
            <span>{{ data.documentDate ? d(data.documentDate, "long") : "" }}</span>
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.PaymentDueDate">
            <span>{{ data.paymentDueDate ? d(data.paymentDueDate, "long") : "" }}</span>
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.IsTransfered">
            <Tag
              :value="
                data.isTransfered
                  ? t('economy-transfer.transfered.transfered')
                  : t('economy-transfer.transfered.not-transfered')
              "
              :severity="data.isTransfered ? 'success' : 'warning'"
            />
          </template>
          <template v-else-if="col.field === EconomyTransferLinesColumns.CreatedDateTime">
            <span>{{ data.createdDateTime ? d(data.createdDateTime, "long") : "" }}</span>
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("economy-transfer.no-transfers-found") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { ref, nextTick, computed } from "vue";
import { EconomyTransferInfo } from "@/models/economy-transfer/EconomyTransferInfo";
import {
  DataTableRowDoubleClickEvent,
  DataTableSortEvent,
  DataTablePageEvent,
  DataTableSelectAllChangeEvent,
} from "primevue/datatable";
import { EconomyTransferLinesColumns } from "@/models/search/economy-transfer/EconomyTransferLinesColumns";
import { useAuth } from "@cumulus/event-bus";

import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { getUser } = useAuth();
const sortField = ref("transferNumber");
const sortOrder = ref(-1);
const visible = ref(false);

const props = defineProps<{
  economyTransfers: EconomyTransferInfo[];
  loading: boolean;
  pageSize: number;
}>();

const emit = defineEmits<{
  (e: "economyTransferDoubleClicked", value: EconomyTransferInfo): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "economyTransferRefresh"): void;
}>();

const { t, d } = useI18n();
const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  emit("economyTransferDoubleClicked", event.data);
};

const economyTransferSearchColumns: DataTableColumn[] = [
  { field: "transferNumber", header: t("economy-transfer.transfer-number"), sortable: true },
  {
    field: "customerNumber",
    header: t("economy-transfer.customer-number"),
    sortable: true,
  },
  {
    field: "supplierNumber",
    header: t("economy-transfer.supplier-number"),
    sortable: true,
  },
  {
    field: "transferDocumentType",
    header: t("economy-transfer.document-type.label"),
    sortable: true,
  },
  {
    field: "documentDate",
    header: t("economy-transfer.document-date"),
    sortable: true,
  },
  {
    field: "paymentDueDate",
    header: t("economy-transfer.payment-due-date"),
    sortable: true,
  },
  { field: "isTransfered", header: t("economy-transfer.transfered.label"), sortable: true },
  {
    field: "createdDateTime",
    header: t("economy-transfer.created"),
    sortable: true,
  },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences(
  "economyTransferSearch",
  economyTransferSearchColumns,
  null,
  (await getUser()).getEmployee().id,
);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== EconomyTransferLinesColumns.TransferNumber &&
      col.field !== EconomyTransferLinesColumns.CustomerNumber &&
      col.field !== EconomyTransferLinesColumns.SupplierNumber,
  );
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickEconomyTransferRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? economyTransferSearchColumns
    : economyTransferSearchColumns.filter(
        (c) =>
          c.field === EconomyTransferLinesColumns.TransferNumber ||
          c.field === EconomyTransferLinesColumns.CustomerNumber ||
          c.field === EconomyTransferLinesColumns.SupplierNumber,
      );
};

const onClickEconomyTransferRefresh = () => {
  emit("economyTransferRefresh");
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>
