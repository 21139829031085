<template>
  <template v-if="customerType === CustomerType.Private">
    <FloatLabel variant="on">
      <InputText
        id="contact-person-input"
        v-model="name"
        v-debounce:200="updateContactPerson"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('creditnote.contact.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="contact-person-input"
        class="w-full mb-4"
        :fluid="true"
        :disabled="disabled"
        :maxlength="500"
        aria-describedby="contact-person-input-help"
        autofocus
      />
      <label for="contact-person-input">
        {{ t(`creditnote.contact.label`) }}
      </label>
    </FloatLabel>
  </template>
  <template v-else>
    <FloatLabel variant="on">
      <InputText
        id="contact-search-input"
        v-model="contactName"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('creditnote.contact.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        :class="{ 'p-disabled': disabled }"
        data-testId="contact-search-input"
        :fluid="true"
        @click="showContactPanel"
      />
      <label for="contact-search-input" class="c-required">{{ t("creditnote.contact.label") }}</label>
    </FloatLabel>

    <CustomerContactPanel
      ref="contactPanelRef"
      :contact="contactPerson"
      :customer-contacts="customerContacts"
      @contact-selected="onContactSelected"
    />
  </template>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { CreditNoteContact } from "@/models/credit-note/CreditNoteContact";
import { computed, nextTick, ref, watch } from "vue";
import type { CustomerContact } from "@/models/customer/CustomerContact";
import CustomerContactPanel from "./CustomerContactPanel.vue";
import { CustomerType } from "@/models/customer/CustomerType";

const { t } = useI18n();

const contactPerson = defineModel<CreditNoteContact>("contactPerson", {
  required: true,
});

defineProps<{
  disabled?: boolean;
  customerContacts: CustomerContact[];
  customerType: CustomerType;
}>();

const name = ref("");

watch(
  () => contactPerson,
  (newContact) => {
    name.value = newContact.value.firstName + " " + newContact.value.lastName;
  },
  { deep: true },
);

const updateContactPerson = () => {
  const splitName = name.value.trim().split(" ");
  contactPerson.value.lastName = splitName.length > 1 ? (splitName.pop() as string) : "";
  contactPerson.value.firstName = splitName.join(" ");
};

const onContactSelected = (selectedContact: CustomerContact) => {
  contactPerson.value.firstName = selectedContact.firstName;
  contactPerson.value.lastName = selectedContact.lastName;
  contactPerson.value.email = selectedContact.email;

  nextTick(() => {
    document.getElementById("contact-email")?.focus();
  });
};

const contactPanelRef = ref();
const showContactPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  contactPanelRef.value.toggle(event);
};

const contactName = computed<string>(() => {
  if (contactPerson.value.firstName && contactPerson.value.lastName) {
    return contactPerson.value.firstName + " " + contactPerson.value.lastName;
  } else {
    return "";
  }
});
</script>
