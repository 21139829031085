import { CreditNote } from "../models/credit-note/CreditNote";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { type SearchCustomer } from "@/models/search/customer/SearchCustomer";
import { CustomerContact } from "@/models/customer/CustomerContact";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { Customer } from "@/models/customer/Customer";
import { useCustomerService } from "@/api/customer/CustomerService";
import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useI18n } from "vue-i18n";
import { NewCreditNote } from "@/models/credit-note/NewCreditNote";
import { creditNoteApi } from "@/api/credit-note/CreditNoteApi";
import { Client } from "@/models/client/Client";
import { CreditNoteClient } from "@/models/credit-note/CreditNoteClient";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { CustomerType } from "@/models/customer/CustomerType";
import { useInvoiceService } from "@/api/invoice/InvoiceService";
import { Invoice } from "@/models/invoice/Invoice";
import { type ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";
import { CreditNoteRequest } from "@/models/credit-note/CreditNoteRequest";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";

export const useCreditNoteCreateStore = defineStore("credit-note-create-store", () => {
  const initialCreditNote = ref<CreditNote>(new CreditNote());
  const currentCreditNote = ref<CreditNote>(new CreditNote());
  const customerContacts = ref<CustomerContact[]>([]);
  const creditableRows = ref<CreditableRow[]>([]);
  const initialInvoice = ref<Invoice | null>(null);
  const customerType = ref<CustomerType>(CustomerType.Private);
  const isSaving = ref(false);

  const toast = useCumulusToast(useToast());
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { t } = useI18n();

  const { getCustomerById } = useCustomerService();
  const { getInvoiceById } = useInvoiceService();

  const createCreditNote = async (returnProducts?: ReturnProductInfo[] | undefined) => {
    isSaving.value = true;
    try {
      const authHeaders = await getAuthHeaders();
      const newCreditNote = NewCreditNote.createFromCreditNote(currentCreditNote.value);

      const request = CreditNoteRequest.createFromReturnProducts(newCreditNote, returnProducts);

      await creditNoteApi.create(authHeaders, request);
      addToast(
        SeverityType.Success,
        t("creditnote.add.toast.success.summary"),
        t("creditnote.add.toast.success.detail"),
      );
      currentCreditNote.value = new CreditNote();
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      isSaving.value = false;
    }
  };

  enum SeverityType {
    Error = "error",
    Warning = "warn",
    Success = "success",
  }

  const addToast = (severity: SeverityType, summary: string, message: string, closable = true): void => {
    toast.add({
      severity: severity,
      summary: summary,
      detail: message,
      closable: closable,
    });
  };

  const setInitialValuesFromInvoice = async (invoiceId: string) => {
    if (initialInvoice.value !== null) {
      return;
    }

    const invoice = await getInvoiceById(invoiceId);
    initialInvoice.value = invoice;
    currentCreditNote.value.currencyIso = invoice.currencyIso;
    currentCreditNote.value.kid = invoice.kid;
    currentCreditNote.value.orderedBy = invoice.orderedBy;
    currentCreditNote.value.client = {
      clientId: invoice.client.clientId,
      name: invoice.client.name,
      businessNumber: invoice.client.businessNumber,
      phoneNumber: invoice.client.phoneNumber,
      email: invoice.client.email,
      address: invoice.client.address,
      accountNumber: invoice.accountNumber,
      bankName: invoice.bankName,
      logoBase64: invoice.client.logoBase64,
    };
    currentCreditNote.value.shippingPrice = invoice.shippingPrice;
    currentCreditNote.value.sumTotalLines = invoice.sumTotalLines;
  };

  const addCreditableRowLineFromProduct = (row: CreditableRow) => {
    creditableRows.value.push(row);
  };

  const addCreditableRowLineFromInvoice = async (row: CreditableRow) => {
    if (currentCreditNote.value.orderedBy.id == "") {
      await setCustomerFromRow(row);
    }

    if (initialInvoice.value === null && row.invoiceId) {
      await setInitialValuesFromInvoice(row.invoiceId);
    }

    if (initialInvoice.value && initialInvoice.value.id !== row.invoiceId && row.invoiceId) {
      const invoice = await getInvoiceById(row.invoiceId);

      if (invoice.currencyIso !== initialInvoice.value.currencyIso) {
        addToast(
          SeverityType.Warning,
          t("creditnote.add.toast.could-not-add-line"),
          t("creditnote.add.toast.too-many-currencies"),
        );

        return;
      }

      if (invoice.client.clientId !== initialInvoice.value.client.clientId) {
        addToast(
          SeverityType.Warning,
          t("creditnote.add.toast.could-not-add-line"),
          t("creditnote.add.toast.too-many-clients"),
        );
        return;
      }
    }

    creditableRows.value.push(row);
  };

  const setClientInformation = (client: Client) => {
    const creditNoteClient = CreditNoteClient.createFromClient(client);
    currentCreditNote.value.client = creditNoteClient;
    currentCreditNote.value.currencyIso = client.currencyIso;
  };

  const setInitialCreditNote = () => {
    initialCreditNote.value = cloneDeep(currentCreditNote.value);
  };

  const setCustomerFromRow = async (row: CreditableRow) => {
    const customer = await getCustomerById(row.customerId);

    if (customer) {
      setCustomerData(customer);
    }
  };

  const setCustomerFromSearch = async (searchCustomer: SearchCustomer) => {
    const customer = await getCustomerById(searchCustomer.id);

    if (customer) {
      setCustomerData(customer);
    }
  };

  const setCustomerData = (customer: Customer) => {
    currentCreditNote.value.creditNoteLines = [];
    creditableRows.value = [];

    if (customer.customerType == CustomerType.Business) {
      customerType.value = CustomerType.Business;
      currentCreditNote.value.orderedBy.id = customer.id;
      currentCreditNote.value.orderedBy.customerName = customer.name;
      currentCreditNote.value.orderedBy.organizationNumber = customer.businessCustomer?.organizationNumber || "";
      currentCreditNote.value.orderedBy.phoneNumber = customer.phoneNumber;
      currentCreditNote.value.orderedBy.email = customer.email;
      currentCreditNote.value.orderedBy.customerNumber = customer.customerNumber;
      currentCreditNote.value.orderedBy.documentLanguageIso = customer.documentLanguageIso;
      currentCreditNote.value.orderedBy.address = customer.address;

      const contact = customer.businessCustomer?.contacts.find((c) => c.isInvoiceRecipient);

      currentCreditNote.value.contactPerson.firstName = contact?.firstName || "";
      currentCreditNote.value.contactPerson.lastName = contact?.lastName || "";
      currentCreditNote.value.contactPerson.email = contact?.email || "";

      customerContacts.value =
        customer.businessCustomer?.contacts.map((c) => {
          return {
            id: c.id,
            firstName: c.firstName,
            lastName: c.lastName,
            name: c.firstName + " " + c.lastName,
            email: c.email,
            phoneNumber: c.phoneNumber,
            isInvoiceRecipient: c.isInvoiceRecipient,
          };
        }) || [];
    }

    if (customer.customerType == CustomerType.Private) {
      customerType.value = CustomerType.Private;
      currentCreditNote.value.orderedBy.id = customer.id;
      currentCreditNote.value.orderedBy.customerName = customer.name;
      currentCreditNote.value.orderedBy.customerNumber = customer.customerNumber;
      currentCreditNote.value.orderedBy.phoneNumber = customer.phoneNumber;
      currentCreditNote.value.orderedBy.email = customer.email;
      currentCreditNote.value.orderedBy.documentLanguageIso = customer.documentLanguageIso;
      currentCreditNote.value.orderedBy.address = customer.address;

      currentCreditNote.value.contactPerson.firstName = customer.privateCustomer?.firstName || "";
      currentCreditNote.value.contactPerson.lastName = customer.privateCustomer?.lastName || "";
      currentCreditNote.value.contactPerson.email = customer.email;

      customerContacts.value = [];
    }
  };

  const addNoReferenceLine = () => {
    const noReferenceLine = CreditableRow.createNoReferenceRow();
    creditableRows.value.push(noReferenceLine);
  };

  const isCreditNoteChanged = computed(() => {
    return !isEqual(initialCreditNote.value, currentCreditNote.value);
  });

  const clearStore = () => {
    initialCreditNote.value = new CreditNote();
    initialInvoice.value = null;
    currentCreditNote.value = new CreditNote();
    creditableRows.value = [];
    customerContacts.value = [];
  };

  return {
    currentCreditNote,
    initialCreditNote,
    creditableRows,
    customerContacts,
    customerType,
    isCreditNoteChanged,
    isSaving,
    clearStore,
    setCustomerFromSearch,
    addCreditableRowLineFromInvoice,
    addCreditableRowLineFromProduct,
    createCreditNote,
    setClientInformation,
    setInitialCreditNote,
    addNoReferenceLine,
  };
});
