<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('number-series.dialog.header', { type: t(`number-series.${numberSeries.seriesType.toLowerCase()}`) })"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    data-testid="dialog-number-series"
  >
    <div class="c-edit-number-series">
      <div class="flex">
        <div>{{ t("number-series.dialog.change-number-series") }}</div>
      </div>

      <div class="flex flex-wrap md:flex-wrap">
        <div class="flex items-center justify-center m-2">
          <div>
            <label for="from-number" class="mb-2">{{ t("number-series.dialog.start-number") }}</label>
            <div id="from-number" data-testid="from-number">
              <InputNumber
                v-model="numberSeriesRef.startNumber"
                data-testid="start-number"
                :min="0"
                :highlightOnFocus="true"
                ref="fromInputRef"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center m-2">
          <div>
            <label for="last-used-number" class="mb-2">{{ t("number-series.dialog.last-used") }}</label>
            <div id="last-used-number" data-testid="last-used-number">
              <InputNumber :model-value="numberSeries.lastUsedNumber" data-testid="input-last-used" disabled />
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container">
        <div class="flex items-center justify-center"></div>
        <div class="flex items-center justify-center">
          <PrimeButton
            class="c-dialog-default-button"
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="visible = false"
            ref="cancelButtonRef"
          />
          <PrimeButton
            class="c-dialog-default-button"
            :label="t('common.save-changes')"
            data-testid="dialog-number-series-save-btn"
            @click="saveNumberSeries"
            :loading="loading"
            :focus="true"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import cloneDeep from "lodash.clonedeep";
import { FinanceNumberSeries } from "../model/finance/FinanceNumberSeries";
import { useFinanceService } from "../api/finance/FinanceService";

const props = defineProps<{
  showDialog: boolean;
  numberSeries: FinanceNumberSeries;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { updateFinanceNumberSeries } = useFinanceService();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const numberSeriesRef = ref<FinanceNumberSeries>(cloneDeep(props.numberSeries));
const loading = ref(false);

const saveNumberSeries = async () => {
  if (numberSeriesRef.value.startNumber === props.numberSeries.startNumber) {
    visible.value = false;
    return;
  }

  try {
    loading.value = true;
    await updateFinanceNumberSeries(numberSeriesRef.value);
  } finally {
    loading.value = false;
  }

  toast.add({
    severity: "success",
    summary: t("number-series.dialog.toast.update-success.summary"),
    detail: t("number-series.dialog.toast.update-success.detail", {
      from: numberSeriesRef.value.startNumber,
    }),
    closable: true,
  });

  visible.value = false;
};
</script>
