<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button mr-4" data-testId="btn-add" @click="navigateToCreditNote">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("creditnote.create") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button" data-testId="btn-add" @click="openInvoiceList">
          <i class="pi pi-list c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("invoice.header") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <div class="c-creditnotes">
    <Card class="m-4">
      <template #content>
        <div>
          <Suspense> <CreditNoteList /></Suspense>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import CreditNoteList from "./CreditNoteList.vue";

const { t } = useI18n();
const router = useRouter();

const navigateToCreditNote = () => {
  router.push({ name: "credit-note-create" });
};

const openInvoiceList = () => {
  router.push({ name: "invoice" });
};
</script>

<style scoped lang="scss"></style>
