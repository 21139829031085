export function useCreditNoteRouteService(clearStore: () => void) {
  const confirmedDiscard = ref(false);

  const router = useRouter();

  const routeToInvoiceList = () => {
    confirmedDiscard.value = true;
    router.push({ name: "invoice" });
    clearStore();
  };

  const routeToCreditNoteList = () => {
    confirmedDiscard.value = true;
    router.push({ name: "credit-note-list" });
    clearStore();
  };

  return {
    routeToInvoiceList,
    routeToCreditNoteList,
  };
}
