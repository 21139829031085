import { AccountGroup } from "@/models/account-group/AccountGroup";
import { AuthHeaders } from "@cumulus/event-bus";
import { httpClient } from "@cumulus/http";

class AccountGroupApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/account-groups"
        : `${import.meta.env.VITE_APP_API_URL as string}/account-groups`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<AccountGroup[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }
}

export const accountGroupApi = new AccountGroupApi();
