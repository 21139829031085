<template>
  <FloatLabelInput
    id="contact-email"
    v-model:value="email"
    :label="t('creditnote.contact.email')"
    :tabIndex="0"
    dataTestId="creditnote-contact-email"
    :placeholder="t('placeholder.type', { property: t('creditnote.contact.email').toLowerCase() })"
    :disabled="disabled"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const email = defineModel<string>("email", {
  required: true,
});

defineProps<{
  disabled?: boolean;
}>();
</script>
