<template>
  <Dialog
    v-model:visible="showDialog"
    :header="t(`payment-term.dialog.header-edit`)"
    :modal="true"
    class="c-edit-dialog"
  >
    <ProgressSpinner v-if="isSaving" />
    <div v-else class="grid grid-cols-12 gap-4 mt-2">
      <div class="flex col-span-12 md:col-span-8">
        <div class="formgrid grid grid-cols-12 gap-4">
          <div class="field col-span-12">
            <Name v-model:name="paymentTermRef.name" />
          </div>

          <div class="field col-span-12">
            <Description v-model:description="paymentTermRef.description" />
          </div>

          <div class="field col-span-12">
            <CreditDays v-model:credit-days="paymentTermRef.creditDays" />
          </div>
        </div>
      </div>

      <div class="flex col-span-12 md:col-span-4">
        <div class="formgrid grid grid-cols-12 gap-4">
          <div class="field col-span-12">
            <ShortName v-model:short-name="paymentTermRef.shortName" />
          </div>

          <div class="field col-span-12">
            <Available
              v-model:for-customers="paymentTermRef.available.forCustomers"
              v-model:for-suppliers="paymentTermRef.available.forSuppliers"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            class="p-button-secondary"
            :disabled="isSaving"
            @click="onCancel"
          />
          <Dialog
            v-model:visible="unsavedChangesDialogVisible"
            data-testid="unsaved-changes-dialog"
            :header="t('common.unsaved-changes-header')"
            :style="{ width: '30vw' }"
            position="center"
            :modal="true"
          >
            <p class="p-6">
              {{ t("common.unsaved-changes-text") }}
            </p>
            <template #footer>
              <Button :label="t('common.cancel')" text @click="unsavedChangesDialogVisible = false" />
              <Button :label="t('common.discard')" text @click="showDialog = false" />
              <Button :label="t('common.save-changes')" icon="pi pi-check" @click="update" />
            </template>
          </Dialog>
        </div>
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.update`)"
            data-testid="update-btn"
            class="p-button-success"
            :disabled="isSaving"
            @click="update"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import Name from "./PaymentTermName.vue";
import ShortName from "./ShortName.vue";
import Description from "./PaymentTermDescription.vue";
import CreditDays from "./CreditDays.vue";
import Available from "./Available.vue";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";

const { updatePaymentTerm } = usePaymentTermStore();

const showDialog = defineModel<boolean>("showDialog", {
  required: true,
});

const props = defineProps<{
  paymentTerm: PaymentTerm;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "update:paymentTerm", value: PaymentTerm): void;
}>();

const paymentTermRef = ref<PaymentTerm>(cloneDeep(props.paymentTerm));
const initialPaymentTerm = ref<PaymentTerm>(cloneDeep(paymentTermRef.value));
const unsavedChangesDialogVisible = ref();
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);

const update = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;

    await updatePaymentTerm(paymentTermRef.value);
    emit("update:paymentTerm", paymentTermRef.value);

    toast.add({
      severity: "success",
      summary: t("payment-term.toast.update-success.summary"),
      detail: t("payment-term.toast.update-success.detail", { name: paymentTermRef.value.name }),
      closable: true,
    });

    showDialog.value = false;
  } catch {
    toast.add({
      severity: "error",
      summary: t("payment-term.toast.error.summary"),
      detail: t("payment-term.toast.error.detail"),
      closable: true,
    });
  } finally {
    isSaving.value = false;
  }
};

const hasUpdates = computed(() => {
  return !isEqual(paymentTermRef.value, initialPaymentTerm.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    showDialog.value = false;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      unsavedChangesDialogVisible.value = false;
      previouslyFocusedInput.value?.focus();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    showDialog.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-edit-dialog {
  width: 90vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 45rem;
  }
}
</style>
