<template>
  <div v-if="loadFailed" class="c-accountPlans">
    <Card>
      <template #content> {{ t("account-plan.load-failed", { errorReason: errorReason }) }} </template>
    </Card>
  </div>
  <DataTable
    v-model:filters="filter"
    :value="accountPlans"
    data-key="id"
    :auto-layout="true"
    responsive-layout="scroll"
    selection-mode="single"
    :loading="loading"
    striped-rows
    sort-field="number"
    :sort-order="1"
    :paginator="false"
    :rows="100"
    class="c-datatable"
    data-testid="c-accountPlan-data-table"
    @row-dblclick="onRowDblClick"
  >
    <Column
      field="accountNumber"
      :header="t(`account-plan.column.accountNumber`)"
      :sortable="true"
      header-class="c-table-header"
    />
    <Column field="accountName" :header="t(`account-plan.column.name`)" :sortable="true" header-class="c-table-header" />
    <Column
      :header="t(`account-plan.column.forIncoming`)"
      :sortable="true"
      header-class="c-table-header"
      body-class="c-table-body"
    >
      <template #body="{ data }"> <Checkbox v-model="data.forIncoming" class="p-disabled" :binary="true" /> </template
    ></Column>
    <Column
      :header="t(`account-plan.column.forOutgoing`)"
      :sortable="true"
      header-class="c-table-header"
      body-class="c-table-body"
    >
      <template #body="{ data }"> <Checkbox v-model="data.forOutgoing" class="p-disabled" :binary="true" /> </template
    ></Column>
    <template #empty>{{ t("account-plan.empty-list") }} </template>
    <template #loading>{{ t("account-plan.loading-list") }}</template>
  </DataTable>
  <Button
    type="button"
    class="p-button-text w-full"
    data-testid="add-account-plan-btn"
    @click="showAddDialog = true"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </Button>
  <AccountPlanAddDialog v-model:show-dialog="showAddDialog" />
  <AccountPlanUpdateDialog v-model:show-dialog="showEditDialog" :account-plan="accountPlan" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { FilterMatchMode } from "@primevue/core/api";
import { type DataTableRowDoubleClickEvent } from "primevue/datatable";

import AccountPlanAddDialog from "../account-plan/AccountPlanAddDialog.vue";
import AccountPlanUpdateDialog from "../account-plan/AccountPlanUpdateDialog.vue";
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import { storeToRefs } from "pinia";
import { AccountPlan } from "@/models/account-plan/AccountPlan";

const { t } = useI18n();
const loadFailed = ref(false);
const errorReason = ref("");
const loading = ref(false);
const showAddDialog = ref(false);
const showEditDialog = ref(false);

const filter = ref({ global: { value: "", matchMode: FilterMatchMode.CONTAINS } });
const accountPlan = ref<AccountPlan>(new AccountPlan());
const { accountPlans } = storeToRefs(useAccountPlanStore());

const editAccountPlan = (value: AccountPlan) => {
  accountPlan.value = value;
  showEditDialog.value = true;
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editAccountPlan(event.data);
};
</script>
