<template>
  <div v-if="loadFailed" class="c-payment-terms">
    <Card>
      <template #content> {{ t("payment-term.load-failed", { errorReason: errorReason }) }} </template>
    </Card>
  </div>
  <div v-if="!loadFailed" class="c-payment-terms">
    <div>
      <div class="mb-4 mt-2">
        <div class="table-header flex flex-col md:flex-row md:justiify-content-between justify-between flex-wrap">
          <div class="formgroup-inline w-80">
            <span class="p-inputgroup">
              <InputText
                v-model="filter['global'].value"
                :placeholder="t('common.search')"
                data-testid="input-search-payment-terms"
                autofocus
                @focus="selectAllOnFocus"
              />

              <span class="p-inputgroup-addon p-button bg-transparent border-none z-10 -ml-12"
                ><i class="pi pi-search text-zinc-500"
              /></span>
            </span>
          </div>

          <div>
            <Button
              type="button"
              class="p-button-text w-full"
              data-testid="add-payment-term-btn"
              @click="showAddDialog = true"
            >
              <i class="c-plus"></i>
              <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
            </Button>
          </div>
        </div>
      </div>

      <DataTable
        v-model:filters="filter"
        :value="paymentTerms"
        data-key="id"
        :auto-layout="true"
        responsive-layout="scroll"
        selection-mode="single"
        :loading="loading"
        striped-rows
        sort-field="name"
        :sort-order="1"
        :paginator="props.paginator"
        :rows="25"
        class="c-datatable"
        data-testid="c-payment-term-data-table"
        @row-dblclick="onRowDblClick"
      >
        <Column
          field="name"
          :header="t(`payment-term.column.name`)"
          :sortable="true"
          filter-field="name"
          filter-match-mode="contains"
          header-class="c-table-header w-1/12"
        >
        </Column>

        <Column
          field="shortName"
          :header="t(`payment-term.column.short-name`)"
          :sortable="true"
          header-class="c-table-header"
        >
        </Column>

        <Column
          field="description"
          :header="t(`payment-term.column.description`)"
          :sortable="true"
          header-class="c-table-header"
        ></Column>

        <Column
          field="creditDays"
          :header="t(`payment-term.column.credit-days`)"
          :sortable="true"
          header-class="c-table-header"
        ></Column>

        <Column
          field="available.forCustomers"
          :header="t(`payment-term.column.for-customers`)"
          :sortable="true"
          header-class="c-table-header"
          body-class="c-table-body"
        >
          <template #body="{ data }">
            <Checkbox v-model="data.available.forCustomers" class="p-disabled" :binary="true" />
          </template>
        </Column>

        <Column
          field="available.forSuppliers"
          :header="t(`payment-term.column.for-suppliers`)"
          :sortable="true"
          header-class="c-table-header"
          body-class="c-table-body"
          ><template #body="{ data }">
            <Checkbox v-model="data.available.forSuppliers" class="p-disabled" :binary="true" /></template
        ></Column>

        <Column :exportable="false" style="min-width: 8rem" header-class="c-table-header" body-class="c-table-body">
          <template #body="{ data }">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-text mr-2"
              @click="editPaymentTerm(data)"
            />
            <Button
              id="delete-payment-term"
              icon="pi pi-trash"
              class="p-button-rounded p-button-secondary p-button-text"
              @click="confirmDeletePaymentTerm($event, data)"
            />
          </template>
        </Column>

        <template #empty>{{ t("payment-term.empty-list") }} </template>
        <template #loading>{{ t("payment-term.loading-list") }}</template>
      </DataTable>
    </div>
  </div>

  <PaymentTermAddDialog v-if="showAddDialog" v-model:show-dialog="showAddDialog" />

  <PaymentTermEditDialog v-if="showEditDialog" v-model:show-dialog="showEditDialog" :payment-term="paymentTerm" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { InternalServerError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { FilterMatchMode } from "@primevue/core/api";
import { useConfirm } from "primevue/useconfirm";
import { type DataTableRowDoubleClickEvent } from "primevue/datatable";
import { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import PaymentTermAddDialog from "./PaymentTermAddDialog.vue";
import PaymentTermEditDialog from "./PaymentTermEditDialog.vue";
import { usePaymentTermStore } from "@/stores/PaymentTermStore";
import { storeToRefs } from "pinia";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();

const errorReason = ref("");
const loading = ref(false);
const loadFailed = ref(false);
const showAddDialog = ref(false);
const showEditDialog = ref(false);

const props = defineProps<{
  paginator: boolean | undefined;
}>();

const { deletePaymentTerm } = usePaymentTermStore();
const { paymentTerms } = storeToRefs(usePaymentTermStore());

const filter = ref({ global: { value: "", matchMode: FilterMatchMode.CONTAINS } });

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editPaymentTerm(event.data);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const paymentTerm = ref<PaymentTerm>(new PaymentTerm());

const editPaymentTerm = (value: PaymentTerm) => {
  paymentTerm.value = value;
  showEditDialog.value = true;
};

const confirmDeletePaymentTerm = (event: Event, paymentTerm: PaymentTerm) => {
  confirm.require({
    acceptClass: "c-delete-confirm",
    target: event.currentTarget as HTMLElement,
    message: t("payment-term.delete.confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      deletePayment(paymentTerm);
    },
  });
};

const deletePayment = async (paymentTerm: PaymentTerm) => {
  try {
    await deletePaymentTerm(paymentTerm.id);

    toast.add({
      severity: "success",
      summary: t("payment-term.toast.delete-success.summary"),
      detail: t("payment-term.toast.delete-success.detail", { name: paymentTerm.name }),
      closable: true,
    });
  } catch (error) {
    let errorMessage = t("common.error-detail.unknown");

    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    }

    errorReason.value = errorMessage;
    toast.add({
      severity: "error",
      summary: t("payment-term.toast.error.summary"),
      detail: errorReason.value,
      closable: true,
    });
  }
};
</script>

<style lang="scss" scoped>
.c-payment-terms {
  margin: var(--default-content-margin);
}
:deep(.p-datatable .p-datatable-thead > tr > th.c-table-header) {
  text-align: center;
}

:deep(.p-datatable .p-datatable-tbody > tr > td.c-table-body) {
  text-align: center;
}
</style>
