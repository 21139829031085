<template>
  <Dialog v-model:visible="visible" :header="t(`tax.dialog.header-edit`)" :modal="true" class="c-edit-dialog">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-4">
        <div class="field col-span-12">
          <div class="flex items-center">
            <div class="w-6/12 mr-2">
              <Name v-model:name="taxRef.taxName" />
            </div>
            <div class="mt-4">
              <label for="is-active" data-testid="is-active-label">
                {{ t(`tax.dialog.isActive.label`) }}
              </label>
              <Checkbox
                v-model="taxRef.isActive"
                input-id="is-active"
                :binary="true"
                data-testid="is-active-checkbox"
                class="ml-2"
              />
            </div>
          </div>
        </div>
        <div class="field col-span-12">
          <Description v-model:description="taxRef.description" />
        </div>
        <div class="field col-span-12">
          <Country v-model:country="taxRef.countryIso" :country-options="countryOptions" />
        </div>
        <div class="field col-span-6">
          <Rate v-model:rate="taxRef.rate" />
        </div>
        <div class="field col-span-6">
          <div class="field">
            <label for="tax-taxtype"> {{ t(`tax.dialog.taxtype.label`) }} </label>
            <Select
              id="tax-taxtype"
              v-model="taxRef.taxType"
              option-label="name"
              option-value="code"
              data-testid="tax-taxtype"
              aria-describedby="tax-taxtype-help"
              class="inputfield w-full"
              :options="taxTypes"
            />
          </div>
        </div>
        <div class="field col-span-12 justify-center flex">
          <strong>
            <span data-testid="default-tax-label">
              {{ t(`tax.dialog.default-tax`) }}
            </span>
          </strong>
        </div>
        <div class="field col-span-6">
          <div class="field-checkbox">
            <label for="tax-forProduct" data-testid="tax-forProduct-label">
              {{ t(`tax.dialog.forProduct.label`) }}
            </label>
            <Checkbox
              v-model="taxRef.isDefaultTaxForProduct"
              input-id="tax-forProduct"
              :binary="true"
              data-testid="c-tax-forProduct"
              class="ml-2"
            />
          </div>
        </div>
        <div class="field col-span-6">
          <div class="field-checkbox">
            <label for="tax-forFreight" data-testid="tax-forFreight-label">
              {{ t(`tax.dialog.forFreight.label`) }}
            </label>
            <Checkbox
              v-model="taxRef.isDefaultTaxForFreight"
              input-id="tax-forFreight"
              :binary="true"
              data-testid="c-tax-forFreight"
              class="ml-2"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            class="p-button-secondary"
            @click="cancel"
          />
        </div>
        <div class="flex items-center justify-center">
          <Button :label="t(`common.update`)" data-testid="update-btn" class="p-button-success" @click="editFee" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import cloneDeep from "lodash.clonedeep";
import { useCumulusToast } from "@cumulus/components";

import Name from "./TaxName.vue";
import Description from "./TaxDescription.vue";
import Rate from "./Rate.vue";
import Country from "./Country.vue";

import { type Country as CountryModel } from "@/models/country/Country";
import { useCountry } from "@/api/country/CountryService";
import { Tax } from "@/models/tax/Tax";
import { useTaxStore } from "@/stores/TaxStore";
import { Options } from "@/models/tax/Options";
import { TaxType } from "@/models/tax/Taxtype";

const taxRef = ref<Tax>(new Tax());
const countryOptions = ref<CountryModel[]>([]);
const { getCountries } = useCountry();

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const { updateTax } = useTaxStore();

const props = defineProps<{
  showDialog: boolean;
  tax: Tax;
}>();

const taxTypes = ref<Options[]>([]);

const fetchTaxTypes = () => {
  for (const taxtype in TaxType) {
    taxTypes.value.push({
      name: t(`tax.dialog.taxtype.${taxtype.toString().toLowerCase()}`),
      code: taxtype.toString(),
    });
  }
};

const fetchCountryData = async () => {
  countryOptions.value = await getCountries();
};

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "update:tax", value: Tax): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

watch(visible, (newValue: boolean) => {
  if (newValue) {
    taxRef.value = cloneDeep(props.tax);
  }
});

const cancel = () => {
  visible.value = false;
};

const editFee = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    await updateTax(taxRef.value);

    toast.add({
      severity: "success",
      summary: t("tax.toast.update-success.summary"),
      detail: t("tax.toast.update-success.detail", { name: taxRef.value.taxName }),
      closable: true,
    });

    visible.value = false;
  } catch {
    toast.add({
      severity: "error",
      summary: t("tax.toast.error.summary"),
      detail: t("tax.toast.error.detail"),
      closable: true,
    });
  }
};

onMounted(() => {
  fetchTaxTypes();
  fetchCountryData();
});
</script>

<style scoped lang="scss">
.c-update-dialog {
  width: 54dvw;
  max-width: 35rem;

  @media screen and (min-width: 1000px) {
    width: 32dvw;
    max-width: 35rem;
  }
}
</style>
