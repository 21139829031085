<template>
  <DataTable
    :value="vatCodes"
    class="c-datatable"
    responsiveLayout="scroll"
    stripedRows
    sortField="name"
    :sortOrder="1"
    data-testid="c-account-settings-vat-codes-table"
    scrollable
    scrollHeight="75vh"
  >
    <Column :header="t('account-settings.vat-codes.tax')">
      <template #body="{ data, index }">
        <SelectTax
          v-model:taxId="data.taxId"
          :taxes="taxes"
          :vatCodes="vatCodes"
          :dataTestId="`c-account-settings-vat-codes-tax-${index}`"
          :isOutgoing25Percent="taxIsInUse(data.taxId)"
          :isRequired="true"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.vat-codes.description')">
      <template #body="{ data, index }">
        <span :data-testid="`c-account-settings-vat-codes-description-${index}`">{{
          taxes.find((tax) => tax.id === data.taxId)?.description
        }}</span>
      </template>
    </Column>
    <Column :header="t('account-settings.vat-codes.rate')">
      <template #body="{ data, index }">
        <span :data-testid="`c-account-settings-vat-codes-rate-${index}`">{{
          taxes.find((tax) => tax.id === data.taxId)?.rate
        }}</span>
      </template>
    </Column>
    <Column :header="t('account-settings.vat-codes.vat-code')" class="w-28">
      <template #body="{ data, index }">
        <VatCodeInput
          v-model:vatCode="data.code"
          :dataTestId="`c-account-settings-vat-codes-code-${index}`"
          :isRequired="true"
        />
      </template>
    </Column>
    <Column>
      <template #header>
        <PrimeButton
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          :disabled="true"
          :aria-label="t('common.delete')"
        />
      </template>

      <template #body="slotProps">
        <i
          v-if="taxIsInUse(slotProps.data.taxId)"
          v-tooltip="{ value: t('account-settings.tax-is-in-use') }"
          class="pi pi-trash c-disabled"
        ></i>

        <PrimeButton
          v-else
          icon="pi pi-trash"
          class="p-button-rounded p-button-plain p-button-text"
          :data-testid="`purchase-order-line-delete-button-${slotProps.index}`"
          @click="deleteTax(slotProps.data)"
        />
      </template>
    </Column>

    <template #empty>
      <span>{{ t("account-settings.no-vat-settings") }}</span>
    </template>
  </DataTable>

  <PrimeButton class="p-button-text p-button-sm" data-testid="c-account-settings-add-vat-code" @click="addNewVatCode">
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </PrimeButton>

  <Popover ref="vatCodesOverlay" appendTo="body" class="c-overlay-panel">
    <i class="pi pi-info-circle"></i>
    <span class="pl-2">{{ t("account-settings.no-taxes-available") }}</span>
  </Popover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { VatCode } from "@/models/account-settings/VatCode";
import { storeToRefs } from "pinia";
import { useTaxStore } from "@/stores/TaxStore";
import { ref } from "vue";
import SelectTax from "./SelectTax.vue";
import VatCodeInput from "./VatCodeInput.vue";

const vatCodes = defineModel<VatCode[]>("vatCodes", {
  required: true,
});

const { t } = useI18n();
const { taxes } = storeToRefs(useTaxStore());
const vatCodesOverlay = ref();

const addNewVatCode = (event: Event) => {
  const availableTaxes = taxes.value.filter((tax) => {
    return !vatCodes.value.some((vatCode) => {
      return vatCode.taxId === tax.id;
    });
  });

  if (availableTaxes.length === 0) {
    vatCodesOverlay.value.toggle(event);
    setTimeout(() => {
      vatCodesOverlay.value.hide();
    }, 2000);
    return;
  }

  const newVatCode = new VatCode();
  newVatCode.code = "0";

  vatCodes.value.push(newVatCode);
};

const taxIsInUse = (taxId: string): boolean => {
  return taxes.value.some(
    (tax) => (tax.id === taxId && tax.isActive && tax.isDefaultTaxForFreight) || tax.isDefaultTaxForProduct,
  );
};

const deleteTax = (vatCode: VatCode) => {
  const index = vatCodes.value.indexOf(vatCode);
  vatCodes.value.splice(index, 1);
};
</script>

<style lang="scss" scoped>
.c-disabled {
  cursor: pointer;
  opacity: 0.6;
}
</style>
