<template>
  <div class="c-margin">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 xl:col-span-12">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 xl:col-span-4">
            <Card>
              <template #content>
                <div class="h-full">
                  <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-transfered">
                        {{ t("economy-transfer.transfered.label") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <Tag
                        :value="
                          economyTransfer.isTransfered
                            ? t('economy-transfer.transfered.transfered')
                            : t('economy-transfer.transfered.not-transfered')
                        "
                        :severity="economyTransfer.isTransfered ? 'success' : 'warning'"
                      />
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-transfer-number">
                        {{ t("economy-transfer.transfer-number") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-transfer-number" data-testid="c-economy-transfer-transfer-number">{{
                        economyTransfer.transferNumber
                      }}</span>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-document-number">
                        {{ t("economy-transfer.document-number") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-document-number" data-testid="c-economy-transfer-document-number">{{
                        economyTransfer.documentNumber
                      }}</span>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-transfer-document-type">
                        {{ t("economy-transfer.document-type.label") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span
                        id="c-economy-transfer-transfer-document-type"
                        data-testid="c-economy-transfer-transfer-document-type"
                        >{{
                          t(`economy-transfer.document-type.${economyTransfer.transferDocumentType.toLowerCase()}`)
                        }}</span
                      >
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-currency">
                        {{ t("economy-transfer.currency.label") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-currency" data-testid="c-economy-transfer-currency">{{
                        economyTransfer.currencyIso
                      }}</span>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-currency-unit">
                        {{ t("economy-transfer.currency.unit") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-currency-unit" data-testid="c-economy-transfer-currency-unit">{{
                        economyTransfer.currencyUnit
                      }}</span>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-exchange-rate">
                        {{ t("economy-transfer.currency.exchange-rate") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-exchange-rate" data-testid="c-economy-transfer-exchange-rate">{{
                        economyTransfer.exchangeRate
                      }}</span>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-attachment-type-code">
                        {{ t("economy-transfer.attachment-type-code") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span
                        id="c-economy-transfer-attachment-type-code"
                        data-testid="c-economy-transfer-attachment-type-code"
                        >{{ economyTransfer.attachmentTypeCode }}</span
                      >
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>

          <CustomerInfo v-if="economyTransfer.customer" :customer="economyTransfer.customer" />
          <PurchaseOrderDetails
            v-if="economyTransfer.supplier"
            :supplier="economyTransfer.supplier"
            :purchase-order-number="economyTransfer.purchaseOrderNumber"
          />

          <div class="col-span-12 xl:col-span-4">
            <Card>
              <template #content>
                <div class="h-full">
                  <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-document-date">
                        {{ t(`economy-transfer.${economyTransfer.transferDocumentType.toLowerCase()}-date`) }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-document-date" data-testid="c-economy-transfer-document-date">{{
                        economyTransfer.documentDate ? d(economyTransfer.documentDate, "long") : ""
                      }}</span>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-payment-due-date">
                        {{ t("economy-transfer.payment-due-date") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span
                        id="c-economy-transfer-payment-due-date"
                        data-testid="c-economy-transfer-payment-due-date"
                        >{{ economyTransfer.paymentDueDate ? d(economyTransfer.paymentDueDate, "long") : "" }}</span
                      >
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <label for="c-economy-transfer-created">
                        {{ t("economy-transfer.created") }}
                      </label>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <span id="c-economy-transfer-created" data-testid="c-economy-transfer-created">{{
                        economyTransfer.createdDateTime ? d(economyTransfer.createdDateTime, "long") : ""
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
      <div class="col-span-12 xl:col-span-12">
        <Card>
          <template #content>
            <EconomyTransferLines :economy-transfer-lines="economyTransfer.lines" :loading="loading" :page-size="20" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEconomyTransferService } from "@/api/economy-transfer/EconomyTransferService";
import { EconomyTransfer } from "@/models/economy-transfer/EconomyTransfer";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import EconomyTransferLines from "@/components/economy-transfer/EconomyTransferLines.vue";
import CustomerInfo from "@/components/economy-transfer/EconomyCustomerInfo.vue";
import PurchaseOrderDetails from "@/components/economy-transfer/PurchaseOrderDetails.vue";

const route = useRoute();
const { getEconomyTransferById } = useEconomyTransferService();

const economyTransfer = ref<EconomyTransfer>(new EconomyTransfer());
const loading = ref<boolean>(false);
const { t, d } = useI18n();

const fetchEconomyTransfer = async (id: string) => {
  try {
    loading.value = true;
    economyTransfer.value = await getEconomyTransferById(id);
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  const id = route.params.id as string;
  fetchEconomyTransfer(id);
});
</script>

<style scoped lang="scss">
.c-margin {
  margin: var(--default-content-margin);
}
</style>
