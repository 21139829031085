import { httpClient, AuthHeaders } from "@cumulus/http";
import { Invoice } from "@/models/invoice/Invoice";

class InvoiceApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/invoices"
        : `${import.meta.env.VITE_APP_API_URL as string}/invoices`;
  }

  public async list(authHeaders: AuthHeaders): Promise<Invoice[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async getInvoicesByCustomerNumber(authHeaders: AuthHeaders, customerNumber: string): Promise<Invoice[]> {
    return await httpClient(this.uri, authHeaders)
      .get(customerNumber)
      .then(({ data }) => data);
  }

  public async getById(authHeaders: AuthHeaders, id: string): Promise<Invoice> {
    return await httpClient(this.uri, authHeaders)
      .get(`/${id}`)
      .then(({ data }) => data);
  }
}

export const invoiceApi = new InvoiceApi();
