import { httpClient, AuthHeaders } from "@cumulus/http";
import { CustomerSearchRequest } from "@/models/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "@/models/search/customer/CustomerSearchResponse";
import { InvoiceSearchRequest } from "@/models/search/invoice/InvoiceSearchRequest";
import { InvoiceSearchResponse } from "@/models/search/invoice/InvoiceSearchResponse";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import { ProductSearchResponse } from "@/models/search/product/ProductSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != "undefined" && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async customerSearch(
    authHeaders: AuthHeaders,
    request: CustomerSearchRequest,
  ): Promise<CustomerSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/customers/search", request)
      .then(({ data }) => data);
  }

  public async invoiceSearch(authHeaders: AuthHeaders, request: InvoiceSearchRequest): Promise<InvoiceSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/invoices/search", request)
      .then(({ data }) => data);
  }

  public async productSearch(authHeaders: AuthHeaders, request: ProductSearchRequest): Promise<ProductSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }
}

const searchApi = new SearchApi();
export { searchApi };
