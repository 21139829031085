<template>
  <template v-if="customerType === CustomerType.Private">
    <FloatLabelInput
      id="contact-person-input"
      v-model:value="name"
      v-debounce:200="updateContactPerson"
      dataTestId="contact-person-input"
      :label="t('creditnote.contact.label')"
      :placeholder="t('placeholder.type', { property: t('creditnote.contact.label').toLowerCase() })"
      :disabled="disabled"
    />
  </template>
  <template v-else>
    <FloatLabelDropdownPanel
      id="contact-search-input"
      :selectedItemName="contactName"
      :label="t('creditnote.contact.label')"
      :selectLabel="t('placeholder.select', { property: t('creditnote.contact.label').toLowerCase() })"
      :disabled="disabled"
      dataTestId="contact-search-input"
      :class="{ 'p-disabled': disabled }"
      @toggleDropdownPanel="showContactPanel"
    />

    <CustomerContactPanel
      ref="contactPanelRef"
      :contact="contactPerson"
      :customerContacts="customerContacts"
      @contactSelected="onContactSelected"
    />
  </template>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { CreditNoteContact } from "@/models/credit-note/CreditNoteContact";
import { computed, nextTick, ref, watch } from "vue";
import type { CustomerContact } from "@/models/customer/CustomerContact";
import CustomerContactPanel from "./CustomerContactPanel.vue";
import { CustomerType } from "@/models/customer/CustomerType";

const { t } = useI18n();

const contactPerson = defineModel<CreditNoteContact>("contactPerson", {
  required: true,
});

defineProps<{
  disabled?: boolean;
  customerContacts: CustomerContact[];
  customerType: CustomerType;
}>();

const name = ref("");

watch(
  () => contactPerson,
  (newContact) => {
    name.value = newContact.value.firstName + " " + newContact.value.lastName;
  },
  { deep: true },
);

const updateContactPerson = () => {
  const splitName = name.value.trim().split(" ");
  contactPerson.value.lastName = splitName.length > 1 ? (splitName.pop() as string) : "";
  contactPerson.value.firstName = splitName.join(" ");
};

const onContactSelected = (selectedContact: CustomerContact) => {
  contactPerson.value.firstName = selectedContact.firstName;
  contactPerson.value.lastName = selectedContact.lastName;
  contactPerson.value.email = selectedContact.email;

  nextTick(() => {
    document.getElementById("contact-email")?.focus();
  });
};

const contactPanelRef = ref();
const showContactPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  contactPanelRef.value.toggle(event);
};

const contactName = computed<string>(() => {
  if (contactPerson.value.firstName && contactPerson.value.lastName) {
    return contactPerson.value.firstName + " " + contactPerson.value.lastName;
  } else {
    return "";
  }
});
</script>
<style>
.c-floating-label {
  color: var(--floating-label-color);
  font-size: 0.9rem;
  font-weight: normal;
}
</style>
