<template>
  <div class="c-number-series grid grid-cols-12 gap-4">
    <div class="col-span-6" v-if="loadFailed">
      {{ t("number-series.load-failed", { errorReason: errorReason }) }}
    </div>

    <div class="col-span-12 lg:col-span-6 lg:border-r lg:pr-4 mb-4">
      <FinanceNumberSeriesList :loading="loading" :numberSeries="financeNumberSeries" />
    </div>

    <div class="col-span-12 lg:col-span-6 mb-4">
      <NumberSeriesList
        :loading="loading"
        :numberSeries="goodsNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>

    <div class="col-span-12 lg:col-span-6 lg:border-r lg:pr-4 mb-4">
      <NumberSeriesList
        :loading="loading"
        :numberSeries="customerNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>

    <div class="col-span-12 lg:col-span-6 mb-4">
      <NumberSeriesList
        :loading="loading"
        :numberSeries="productNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref } from "vue";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { useFinanceService } from "@/number-series/api/finance/FinanceService";
import { useProductService } from "@/number-series/api/product/ProductService";
import { useSupplierService } from "@/number-series/api/supplier/SupplierService";
import { usePurchaseService } from "@/number-series/api/purchase/PurchaseService";
import { useCustomerService } from "@/number-series/api/customer/CustomerService";
import { useOrderService } from "@/number-series/api/order/OrderService";
import { usePickingListNumberSeriesApi } from "@/number-series/api/picking-list/PickingListNumberSeriesService";

import NumberSeriesList from "./NumberSeriesList.vue";
import FinanceNumberSeriesList from "./FinanceNumberSeriesList.vue";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";
import { FinanceNumberSeries } from "../model/finance/FinanceNumberSeries";

const { t } = useI18n();

const loadFailed = ref(false);
const errorReason = ref("");
const loading = ref(false);

const financeNumberSeries = ref<FinanceNumberSeries[]>([]);
const productNumberSeries = ref<NumberSeries>(new NumberSeries());
const supplierNumberSeries = ref<NumberSeries>(new NumberSeries());
const purchaseNumberSeries = ref<NumberSeries>(new NumberSeries());
const customerNumberSeries = ref<NumberSeries>(new NumberSeries());
const orderNumberSeries = ref<NumberSeries>(new NumberSeries());
const pickingListNumberSeries = ref<NumberSeries>(new NumberSeries());

const customerNumberSeriesGroup = computed(() => [customerNumberSeries.value, orderNumberSeries.value]);
const productNumberSeriesGroup = computed(() => [
  productNumberSeries.value,
  purchaseNumberSeries.value,
  supplierNumberSeries.value,
]);
const goodsNumberSeriesGroup = computed(() => [pickingListNumberSeries.value]);

const { getFinanceNumberSeries } = useFinanceService();

const { getProductNumberSeries, updateProductNumberSeries } = useProductService();
const { getSupplierNumberSeries, updateSupplierNumberSeries } = useSupplierService();
const { getPurchaseNumberSeries, updatePurchaseNumberSeries } = usePurchaseService();
const { getCustomerNumberSeries, updateCustomerNumberSeries } = useCustomerService();
const { getOrderNumberSeries, updateOrderNumberSeries } = useOrderService();
const { getGoodsoutNumberSeries, updateGoodsoutNumberSeries } = usePickingListNumberSeriesApi();

const getAllNumberSeries = async () => {
  loading.value = true;
  loadFailed.value = false;
  errorReason.value = "";

  return Promise.allSettled([
    getFinanceNumberSeries().then((data) => (financeNumberSeries.value = data)),
    getProductNumberSeries().then((data) => (productNumberSeries.value = data)),
    getSupplierNumberSeries().then((data) => (supplierNumberSeries.value = data)),
    getPurchaseNumberSeries().then((data) => (purchaseNumberSeries.value = data)),
    getCustomerNumberSeries().then((data) => (customerNumberSeries.value = data)),
    getOrderNumberSeries().then((data) => (orderNumberSeries.value = data)),
    getGoodsoutNumberSeries().then((data) => (pickingListNumberSeries.value = data)),
  ])
    .then(() => {
      loading.value = false;
    })
    .catch((error) => {
      loadFailed.value = true;
      errorReason.value = error.toString();
      loading.value = false;
    });
};

const updateNumberSeries = async (value: NumberSeries) => {
  switch (value.type) {
    case NumberSeriesType.Product:
      await updateProductNumberSeries(value);
      productNumberSeries.value = value;
      break;

    case NumberSeriesType.Supplier:
      await updateSupplierNumberSeries(value);
      supplierNumberSeries.value = value;
      break;

    case NumberSeriesType.Purchase:
      await updatePurchaseNumberSeries(value);
      purchaseNumberSeries.value = value;
      break;

    case NumberSeriesType.Customer:
      await updateCustomerNumberSeries(value);
      customerNumberSeries.value = value;
      break;

    case NumberSeriesType.Order:
      await updateOrderNumberSeries(value);
      orderNumberSeries.value = value;
      break;

    case NumberSeriesType.PickingList:
      await updateGoodsoutNumberSeries(value);
      pickingListNumberSeries.value = value;
      break;
  }
};

onMounted(getAllNumberSeries);
</script>
