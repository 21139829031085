<template>
  <label for="account-settings-client">{{ t("account-settings.client") }}</label>
  <MultiSelect
    v-model="selectedClientIds"
    :options="clients"
    option-label="name"
    option-value="id"
    :placeholder="t('placeholder.select', { property: t('account-settings.clients').toLocaleLowerCase() })"
    class="inputfield w-full"
    :class="{ 'p-invalid': val && val.$error }"
    pt:root:data-testid="c-account-settings-clients"
    pt:list:data-testid="c-account-settings-clients-list"
    pt:dropdown:data-testid="c-account-settings-clients-dropdown"
  />
  <small v-if="val && val.$error" class="p-error" data-testid="c-account-settings-clients-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { Client } from "@/models/client/Client";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { required } from "@/locales/i18n-validators";

defineProps<{
  clients: Client[];
}>();

const selectedClientIds = defineModel<string[]>("selectedClientIds", {
  required: true,
});

const { t } = useI18n();

const rules = {
  selectedClientIds: {
    required,
    minLength: minLength(1),
  },
};

const val = useVuelidate(rules, { selectedClientIds });
</script>
