<template>
  <DataTable
    :value="productSettings"
    class="c-datatable"
    data-key="accountGroupId"
    responsive-layout="scroll"
    striped-rows
    data-testid="c-account-settings-product-table"
  >
    <Column :header="t('account-settings.account-group')" class="max-w-40">
      <template #body="{ data, index }">
        <span
          v-if="isStandardAccountGroup(data.accountGroupId)"
          :data-testid="`c-account-settings-product-account-group-${index}`"
          >{{ t("account-settings.standard") }}</span
        >
        <SelectAccountGroup
          v-else
          v-model:account-group-id="data.accountGroupId"
          :account-groups="filteredAccountGroups(data.accountGroupId)"
          :data-test-id="`c-account-settings-product-account-group-${index}`"
          :is-required="true"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.inventory')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:account-plan-id="data.inventoryAccountPlanId"
          :data-test-id="`c-account-settings-inventory-account-plan-${index}`"
          :is-default="data.isDefault"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.sales')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:account-plan-id="data.salesAccountPlanId"
          :data-test-id="`c-account-settings-sales-account-plan-${index}`"
          :is-default="data.isDefault"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.sales-tax-free')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:account-plan-id="data.salesTaxFreeAccountPlanId"
          :data-test-id="`c-account-settings-sales-tax-free-account-plan-${index}`"
          :is-default="data.isDefault"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.purchase')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:account-plan-id="data.purchaseAccountPlanId"
          :data-test-id="`c-account-settings-purchase-account-plan-${index}`"
          :is-default="data.isDefault"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.purchase-tax-free')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:account-plan-id="data.purchaseTaxFreeAccountPlanId"
          :data-test-id="`c-account-settings-purchase-tax-free-account-plan-${index}`"
          :is-default="data.isDefault"
        />
      </template>
    </Column>
    <Column :header="t('account-settings.cost-goods-sold')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:account-plan-id="data.costGoodsSoldAccountPlanId"
          :data-test-id="`c-account-settings-cost-goods-sold-account-plan-${index}`"
          :is-default="data.isDefault"
        />
      </template>
    </Column>
    <Column>
      <template #header>
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          :disabled="true"
          :aria-label="t('common.delete')"
        />
      </template>

      <template #body="slotProps">
        <Button
          v-if="isStandardAccountGroup(slotProps.data.accountGroupId) === false"
          icon="pi pi-trash"
          class="p-button-rounded p-button-plain p-button-text"
          :data-testid="`purchase-order-line-delete-button-${slotProps.index}`"
          @click="deleteProductSettingLine(slotProps.data)"
        />
      </template>
    </Column>

    <template #empty>
      <span>{{ t("account-settings.no-product-settings") }}</span>
    </template>
  </DataTable>

  <Button
    class="p-button-text p-button-sm"
    data-testid="c-account-settings-add-new-product-setting"
    @click="addNewProductSetting"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </Button>
  <Popover ref="productOverlay" append-to="body" class="c-overlay-panel">
    <i class="pi pi-info-circle"></i>
    <span class="pl-2">{{ t("account-settings.no-account-groups-available") }}</span>
  </Popover>
</template>

<script setup lang="ts">
import { AccountGroup } from "@/models/account-group/AccountGroup";
import { AccountSettingProduct } from "@/models/account-settings/AccountSettingProduct";
import { useI18n } from "vue-i18n";
import SelectAccountPlan from "./SelectAccountPlan.vue";
import { ref } from "vue";
import SelectAccountGroup from "./SelectAccountGroup.vue";

const props = defineProps<{
  accountGroups: AccountGroup[];
}>();

const productSettings = defineModel<AccountSettingProduct[]>("productSettings", {
  required: true,
});

const { t } = useI18n();
const productOverlay = ref();

const isStandardAccountGroup = (accountGroupId: string) => {
  return props.accountGroups.find((accountGroup) => accountGroup.id === accountGroupId)?.isDefault || false;
};

const addNewProductSetting = (event: Event) => {
  const availableAccountGroups = props.accountGroups.filter((accountGroup) => {
    return !productSettings.value.some((productSetting) => {
      return productSetting.accountGroupId === accountGroup.id || !productSetting.isDefault;
    });
  });

  if (availableAccountGroups.length === 0) {
    showNoAccountGroupsAvailableMessage(event);
    return;
  }

  const emptyAddedProductSetting = productSettings.value.find((productSetting) => productSetting.accountGroupId === "");

  if (emptyAddedProductSetting) {
    return;
  }

  const standardProductSetting = findStandardProductSetting();

  if (!standardProductSetting) {
    return;
  }

  productSettings.value.push(new AccountSettingProduct(standardProductSetting));
};

const showNoAccountGroupsAvailableMessage = (event: Event) => {
  productOverlay.value.toggle(event);
  setTimeout(() => {
    productOverlay.value.hide();
  }, 2000);
};

const findStandardProductSetting = () => {
  const standardAccountGroup = props.accountGroups.find((accountGroup) => accountGroup.name === "Standard");
  return productSettings.value.find((productSetting) => productSetting.accountGroupId === standardAccountGroup?.id);
};

const filteredAccountGroups = (accountGroupId: string) => {
  return props.accountGroups.filter((accountGroup) => {
    return !productSettings.value.some((productSetting) => {
      return productSetting.accountGroupId === accountGroup.id && accountGroupId !== accountGroup.id;
    });
  });
};

const deleteProductSettingLine = (productSetting: AccountSettingProduct) => {
  const index = productSettings.value.findIndex((product) => product === productSetting);
  productSettings.value.splice(index, 1);
};
</script>

<style lang="scss">
.c-overlay-panel {
  z-index: 111 !important;
}
</style>
