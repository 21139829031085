<template>
  <label for="currency-select-base-currency">{{ t("currency.base-currency") }}</label>
  <i class="pi pi-info-circle c-info-icon ml-2" :title="t('currency.client-currency')"></i>
  <Select
    id="currency-select-base-currency"
    v-model="baseCurrencyIsoComputed"
    :options="currencyIsoOptions"
    option-label="currencyIso"
    option-value="currencyIso"
    class="w-full"
    data-testid="currency-select-base-currency"
    :disabled="true"
    pt:list:data-testid="currency-select-base-currency-list"
  />
  <small v-if="val.baseCurrencyIso.$error" data-testid="base-currency-iso-error" class="p-error">{{
    val.baseCurrencyIso.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  baseCurrencyIso: string;
  currencyIsoOptions: Record<string, string>[];
}>();

const emit = defineEmits<{
  (e: "update:baseCurrencyIso", value: string): void;
}>();

const { t } = useI18n();

const baseCurrencyIsoComputed = computed<string>({
  get: () => props.baseCurrencyIso,
  set: (value: string) => emit("update:baseCurrencyIso", value),
});

const rules = {
  baseCurrencyIso: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
