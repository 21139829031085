import { httpClient, AuthHeaders } from "@cumulus/http";
import { CreditNote } from "@/models/credit-note/CreditNote";
import { CreditNoteRequest } from "@/models/credit-note/CreditNoteRequest";
import { CreditTotal } from "@/models/credit-note/CreditTotals";

class CreditNoteApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? import.meta.env.VITE_APP_API_FINANCE + "/credit-notes"
        : `${import.meta.env.VITE_APP_API_URL as string}/credit-notes`;
  }

  public async getCreditTotalsForInvoices(authHeaders: AuthHeaders, invoiceIds: string[]): Promise<CreditTotal[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/credit-totals", {
        params: { invoiceIds },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then(({ data }) => data);
  }

  public async getAll(authHeaders: AuthHeaders): Promise<CreditNote[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<CreditNote> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, CreditNoteRequest: CreditNoteRequest): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", CreditNoteRequest);
  }
}

export const creditNoteApi = new CreditNoteApi();
