<template>
  <DataTable
    :value="accountSettings"
    class="c-datatable"
    data-key="id"
    :auto-layout="true"
    responsive-layout="scroll"
    selection-mode="single"
    :loading="loading"
    striped-rows
    sort-field="name"
    :sort-order="1"
    :paginator="true"
    :current-page-report-template="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows="pageSize"
    :rows-per-page-options="[20, 50, 100]"
    data-testid="c-account-settings-list"
    scrollable
    scroll-height="75vh"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClicked"
  >
    <Column field="clientIds" :header="t('account-settings.client')" sortable>
      <template #body="{ data, field }">
        <span>{{ getClientNamesByIds(data[field as keyof typeof data]) }}</span>
      </template>
    </Column>
    <Column field="economySystem" :header="t('account-settings.economy-system')" />
    <Column field="description" :header="t('account-settings.description')" />

    <template #empty>
      <span>{{ t("account-settings.no-account-settings") }}</span>
    </template>
  </DataTable>

  <Button
    class="c-circular-button mr-4"
    data-testid="c-account-settings-add-account-settings-button"
    @click="addAccountSettingDialogVisible = true"
  >
    <i class="pi pi-check c-success-button c-circular-icon"></i>
    <span class="px-4">{{ t("common.add") }}</span>
  </Button>

  <AccountSettingsModal
    v-if="accountSettingsDialogVisible"
    v-model:visible-dialog="accountSettingsDialogVisible"
    :selected-account-settings-id="selectedAccountSettingsId"
    :clients="clients"
  />

  <AddAccountSettingModal
    v-if="addAccountSettingDialogVisible"
    v-model:visible-dialog="addAccountSettingDialogVisible"
    :clients="clients"
    @account-settings-created="onAccountSettingsCreated"
  />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import AccountSettingsModal from "./AccountSettingsModal.vue";
import { type DataTableRowDoubleClickEvent, type DataTableRowSelectEvent } from "primevue/datatable";
import { storeToRefs } from "pinia";
import { useAccountSettingsService } from "@/api/account-settings/AccountSettingsService";
import { useClientStore } from "@/stores/ClientStore";
import AddAccountSettingModal from "./AddAccountSettingModal.vue";
import { AccountSettingsInfo } from "@/models/account-settings/AccountSettingsInfo";

const accountSettings = ref<AccountSettingsInfo[]>([]);
const selectedAccountSettingsId = ref<string>("");
const accountSettingsDialogVisible = ref(false);
const addAccountSettingDialogVisible = ref(false);

const { getAllAccountSettings } = useAccountSettingsService();
const clientStore = useClientStore();
const { clients } = storeToRefs(clientStore);

const loading = ref(false);
const pageSize = ref(20);
const { t } = useI18n();

const getClientNamesByIds = (id: string[]) => {
  return clients.value
    .filter((client) => id.includes(client.id))
    .map((client) => client.name)
    .join(", ");
};

const onAccountSettingsCreated = async (id: string) => {
  accountSettings.value = await getAllAccountSettings();
  selectedAccountSettingsId.value = id;
  accountSettingsDialogVisible.value = true;
};

onMounted(async () => {
  try {
    loading.value = true;
    accountSettings.value = await getAllAccountSettings();
  } finally {
    loading.value = false;
  }
});

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  selectedAccountSettingsId.value = event.data.id;
  accountSettingsDialogVisible.value = true;
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  selectedAccountSettingsId.value = event.data.id;
  accountSettingsDialogVisible.value = true;
};
</script>
