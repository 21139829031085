<template>
  <InputNumber
    :id="`account-settings-attachment-type-code-${index}`"
    v-model="attachmentTypeCode"
    :placeholder="t('placeholder.type', { property: t('account-settings.attachment-types.code').toLowerCase() })"
    :input-class="'inputfield w-full' + (val && val.$error ? ' p-invalid' : '')"
    :data-testid="`c-account-settings-attachment-type-code-${index}`"
    :use-grouping="false"
  />
  <small
    v-if="val && val.$error"
    class="p-error"
    :data-testid="`c-account-settings-attachment-type-code-error-${index}`"
  >
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

defineProps<{
  index: number;
}>();

const attachmentTypeCode = defineModel<number | null>("attachmentTypeCode", {
  required: true,
});

const { t } = useI18n();

const rules = {
  attachmentTypeCode: {
    required: required,
  },
};

const val = useVuelidate(rules, { attachmentTypeCode });
</script>
