import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { BadRequestError, InternalServerError, NotFoundError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useI18n } from "vue-i18n";

export function useErrorHandler() {
  const { instance } = useAuth();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  const handleError = async (error: unknown, showToast = true): Promise<void> => {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return;
    }

    let errorMessage = "";

    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    } else if (error instanceof NotFoundError) {
      errorMessage = t("common.error-detail.not-found");
    } else if (error instanceof BadRequestError) {
      if (error.message.detail) {
        errorMessage = error.message.detail;
      } else if (error.message.errors) {
        const errorFields = Object.values(error.message.errors).flat().join(", ");
        errorMessage = errorFields || t("common.error-detail.bad-request");
      }
    } else {
      errorMessage = t("common.error-detail.unknown");
    }

    if (errorMessage !== "" && showToast) {
      addErrorToast(errorMessage);
    }
  };

  const addErrorToast = (message: string): void => {
    toast.add({
      severity: "error",
      summary: t("common.an-error-occured"),
      detail: message,
      life: 5000,
      closable: true,
    });
  };

  return { handleError };
}
