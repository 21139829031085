<template>
  <label for="payment-term-credit-days">
    {{ t(`payment-term.dialog.credit-days.label`) }}
  </label>
  <InputNumber
    id="payment-term-credit-days"
    v-model="creditDays"
    :min="0"
    data-testid="payment-term-credit-days"
    class="inputfield w-full"
    input-class="w-full"
    :placeholder="t(`payment-term.dialog.credit-days.placeholder`)"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const creditDays = defineModel<number>("creditDays", {
  required: true,
});
</script>
