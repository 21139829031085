import { httpClient, AuthHeaders } from "@cumulus/http";
import { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import { NewPaymentTerm } from "@/models/payment-term/NewPaymentTerm";

class PaymentTermApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/payment-terms`
        : `${import.meta.env.VITE_APP_API_URL as string}/payment-terms`;
  }
  public async getAll(authHeaders: AuthHeaders): Promise<PaymentTerm[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<PaymentTerm> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, paymentTerm: NewPaymentTerm): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", paymentTerm);
  }

  public async update(authHeaders: AuthHeaders, paymentTerm: PaymentTerm): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", paymentTerm);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}
const paymentTermApi = new PaymentTermApi();
export { paymentTermApi };
